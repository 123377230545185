<template>
  <div class="card__custom">
    <b-row class="m-3 align-items-center justify-content-center">
      <b-col>
        <strong>{{ $t("medicine.cycle") }}</strong>
        <div>          
          <b-dropdown
            id="dropdown-right-1"
            :text="selectCycle.label"
            class="m-md-2 dropdown__custom"
            variant="outline-white"
          >
            <div class="header__dropdown">
              <p>{{ $t("medicine.cycle") }}:</p>
            </div>
            <b-dropdown-item
              class="item__dropdown"
              v-for="(item, index) in dataCycles"
              :key="index"
              @click="handleCycle(item)"
            >
              {{ item.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>

      <b-col>
        <strong>{{ $t("medicine.dosage") }}</strong>
        <div>
          
          <b-dropdown
            id="dropdown-right-3"
            :text="selectDosage.label"
            class="m-md-2 dropdown__custom"
            variant="outline-white"
          >
            <div class="header__dropdown">
              <p>
                {{ $t("medicine.dosage") }}:
                <span
                  v-if="
                    selectCycle.label ===
                    `---${this.$i18n.t('medicine.select')}---`
                  "
                  class="text-danger"
                  >{{ $t("medicine.msgDosage") }}</span
                >
              </p>
            </div>
            <b-dropdown-item
              class="item__dropdown"
              v-for="(item, index) in dataDosage"
              :key="index"
              @click="handleDosage(item)"
              >{{ item.label }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
      <b-col>
        <strong>{{ $t("medicine.medicine") }}</strong>
        <div>
          
          <b-dropdown
            id="dropdown-right"
            :text="selectMedicine.label"
            class="m-md-2 dropdown__custom"
            variant="outline-white"
          >
            <div class="header__dropdown">
              <p>
                {{ $t("medicine.medicine") }}:
                <span
                  v-if="
                    selectDosage.label ===
                    `---${this.$i18n.t('medicine.select')}---`
                  "
                  class="text-danger"
                  >{{ $t("medicine.msgMedicine") }}</span
                >
              </p>
            </div>
            <b-dropdown-item
              class="item__dropdown"
              v-for="(item, index) in dataMedicine"
              :key="index"
              @click="handleMedicine(item)"
              >{{ item.label }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
      <b-col sm="3">
        <b-button
          pill
          variant="success"
          class="mr-3"
          v-b-popover.focus.html.v-danger="popoverText"
          :title="titleValid"
          v-if="!isValid"
          ><b-icon icon="check2" aria-hidden="true" />{{
            $t("medicine.apply")
          }}</b-button
        >
        <b-button
          pill
          variant="success"
          class="mr-3"
          @click="handleSearchMedicine"
          v-else
          ><b-icon icon="check2" aria-hidden="true" />{{
            $t("medicine.apply")
          }}</b-button
        >
        <b-button pill variant="outline-secondary" @click="deleteFields">{{
          $t("medicine.delete")
        }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { API_ROOT } from "../../Constants/Api";
import { AlertForm, ICON } from "../../helpers/Alert";
import { Capitalize } from "../../helpers/Capitalize";
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "FilterMedicine",
  data() {
    return {
      initial: {
        label: `---${this.$i18n.t("medicine.select")}---`,
        value: null,
      },
      selectCycle: {},
      selectDosage: {},
      selectMedicine: {},
      dataCycles: [],
      dataDosage: [],
      dataMedicine: [],
      popoverText: this.$i18n.t("medicine.incomplete"),
      titleValid: this.$i18n.t("medicine.warning"),
    };
  },
  async mounted() {
    this.selectCycle = this.initial;
    this.selectDosage = this.initial;
    this.selectMedicine = this.initial;
    await this.getCycle();
  },
  computed: {
    isValid() {
      const Validation = [this.selectCycle, this.selectDosage];
      const filterValidation = Validation.filter((el) => el.value === null);
      return filterValidation.length === 0;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    language() {
      if (!this.selectCycle.value)
        this.selectCycle.label = `---${this.$i18n.t("medicine.select")}---`;
      if (!this.selectDosage.value)
        this.selectDosage.label = `---${this.$i18n.t("medicine.select")}---`;
      if (!this.selectMedicine.value)
        this.selectMedicine.label = `---${this.$i18n.t("medicine.select")}---`;
      (this.popoverText = this.$i18n.t("medicine.incomplete")),
        (this.titleValid = this.$i18n.t("medicine.warning"));
    },
  },
  methods: {
    async getCycle() {
      try {
        const data = await ApiRequest.get(`${API_ROOT}filtrosMedicamentos/carga_combos/ciclo`);
        this.dataCycles = data.records.map((el) => ({
          label: Capitalize(el),
          value: el,
        }));
      } catch (err) {
        return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async handleCycle(cycle) {
      this.selectCycle = cycle;
      this.selectDosage = this.initial;
      this.selectMedicine = this.initial;
      this.dataDosage = [];
      this.dataMedicine = [];
      try {
        const data = await ApiRequest.post(`${API_ROOT}filtrosMedicamentos/dosisXciclo`,
          {
            ciclo: cycle.value,
          }
        );
        this.dataDosage = data.records.map((el) => ({
          label: Capitalize(el),
          value: el,
        }));
      } catch (err) {
        return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },

    async handleDosage(dosage) {
      this.selectDosage = dosage;
      this.selectMedicine = this.initial;
      this.dataMedicine = [];
      try {
        const data = await ApiRequest.post(`${API_ROOT}filtrosMedicamentos/medicamentoXcicloDosis`,
          {
            ciclo: this.selectCycle.value,
            dosis: dosage.value,
          }
        );
        this.dataMedicine = data.records.map((el) => ({
          label: Capitalize(el),
          value: el,
        }));
      } catch (err) {
        return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    handleMedicine(medicine) {
      this.selectMedicine = medicine;
    },
    async deleteFields() {
      this.selectCycle = this.initial;
      this.selectDosage = this.initial;
      this.selectMedicine = this.initial;
      this.dataCycles = [];
      this.dataDosage = [];
      this.dataMedicine = [];
      await this.getCycle();
    },
    async handleSearchMedicine() {
      if (this.$store.state.pharmacy_selected.value === null) {
        return AlertForm(
          this.$i18n.t("proposal.noSelected"),
          "",
          ICON.WARNING,
          "center"
        );
      }
      this.$store.dispatch("filtersSelected", {
        cycle: this.selectCycle.value,
        dosage: this.selectDosage.value,
        medicine: this.selectMedicine.value,
      });
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";
@import "../../assets/css/proposal.css";

/**** DropDown **** */

.dropdown__custom {
  border: 1px solid var(--lightgray-color);
  border-radius: 0.4rem;
  min-width: 150px;
}

.dropdown__custom /deep/ button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.card__custom .header__dropdown {
  display: flex;
  margin: 5px 10px;
  color: rgb(173, 181, 189);
}
.card__custom .header__dropdown > img {
  padding-right: 6px;
}
.card__custom .item__dropdown {
  padding: 0 0.5rem 0 1rem;
  font-size: 0.9rem;
}

/*** Div pharmacy select */

.card__custom {
  background-color: var(--white);
  padding: 2rem 0;
}
</style>