var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "text-center",
      attrs: {
        header:
          (this.coverageConfig[0] &&
            (_vm.country === "MX"
              ? this.coverageConfig[0].label_category
              : this.$i18n.t("proposal.Coverage"))) ||
          this.$i18n.t("proposal.Coverage")
      },
      on: { change: _vm.getRadioValues }
    },
    [
      _c(
        "b-card-text",
        [
          !_vm.coverage.length
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Text Centered" }
                  })
                ],
                1
              )
            : _vm._l(_vm.coverage, function(item, index) {
                return _c("b-form-radio-group", {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var ariaDescribedby = ref.ariaDescribedby
                          return [
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  internal: item.category,
                                  "aria-describedby": ariaDescribedby,
                                  name: item.category,
                                  price: item.price,
                                  value: [item.articulo, item.name],
                                  description: item.name,
                                  currency: item.currency,
                                  data: JSON.stringify(item.data)
                                }
                              },
                              [
                                _c("br"),
                                !_vm.coverage.length
                                  ? [
                                      _c("b", { staticClass: "b-ingen" }, [
                                        _vm._v(_vm._s(item.name) + " ")
                                      ])
                                    ]
                                  : [
                                      _vm.proposalingen.msg != null ||
                                      _vm.proposalingen.msg != undefined
                                        ? _c(
                                            "span",
                                            { staticClass: "no-ingen" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    "ingenes-class": _vm.proposalingen.msg.find(
                                                      function(x) {
                                                        return (
                                                          x.cfgd_articulo ==
                                                            item.alterName &&
                                                          x.cfgd_Categoria ==
                                                            item.category
                                                        )
                                                      }
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "no-ingen" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                    ]
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.coverageSelected,
                    callback: function($$v) {
                      _vm.coverageSelected = $$v
                    },
                    expression: "coverageSelected"
                  }
                })
              }),
          _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "outline-danger" },
              on: {
                click: function($event) {
                  return _vm.resetCheck()
                }
              }
            },
            [_vm._v("Reset")]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }