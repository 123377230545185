var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        header:
          this.becConfig[0] &&
          (_vm.country === "MX"
            ? this.becConfig[0].label_category
            : this.$i18n.t("proposal.ProgramsUsa"))
      },
      on: { change: _vm.getRadioValues }
    },
    [
      _vm.isLoading || _vm.isLoadingProducts
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-spinner", {
                attrs: { variant: "primary", label: "Text Centered" }
              })
            ],
            1
          )
        : _c(
            "b-card-text",
            [
              _c("div", { staticClass: "search-programs" }, [
                _c(
                  "div",
                  { staticClass: "groupSearch mb-3" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "inputSearch", attrs: { size: "sm" } },
                      [
                        _c("b-form-input", {
                          staticClass: "custom-input",
                          attrs: {
                            id: "search-programs",
                            type: "search",
                            placeholder: this.$i18n.t("welcome.search")
                          },
                          model: {
                            value: _vm.filterOptions,
                            callback: function($$v) {
                              _vm.filterOptions = $$v
                            },
                            expression: "filterOptions"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("hr"),
                _c("div", { staticClass: "title-items" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("proposal.Article")))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("proposal.Price")) +
                        " (" +
                        _vm._s(_vm.country === "MX" ? "MXN " : "USD ") +
                        ")"
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                _vm._l(_vm.programas, function(item, index) {
                  return _c("b-form-radio-group", {
                    key: index,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var ariaDescribedby = ref.ariaDescribedby
                            return [
                              _c(
                                "b-form-radio",
                                {
                                  attrs: {
                                    internal: item.category,
                                    "aria-describedby": ariaDescribedby,
                                    name: item.category,
                                    price: item.price,
                                    value: [item.articulo, item.name],
                                    description: item.name,
                                    currency: item.currency,
                                    data: JSON.stringify(item.data)
                                  }
                                },
                                [
                                  item.top
                                    ? [
                                        _c("b", { staticClass: "b-ingen" }, [
                                          _vm._v(_vm._s(item.name) + " "),
                                          _vm.amountTop - 1 === index
                                            ? _c("hr")
                                            : _vm._e()
                                        ]),
                                        _c("b", [
                                          _c(
                                            "span",
                                            { staticClass: "text-secondary" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(item.price)
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    : [
                                        _vm.proposalingen.msg != null ||
                                        _vm.proposalingen.msg != undefined
                                          ? _c(
                                              "span",
                                              { staticClass: "no-ingen" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    class: {
                                                      "ingenes-class": _vm.proposalingen.msg.find(
                                                        function(x) {
                                                          return (
                                                            x.cfgd_articulo ==
                                                              item.alterName &&
                                                            x.cfgd_Categoria ==
                                                              item.category
                                                          )
                                                        }
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(item.name)
                                                    ),
                                                    _c("b", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-secondary"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                item.price
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "no-ingen" },
                                              [
                                                _vm._v(" " + _vm._s(item.name)),
                                                _c("b", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-secondary"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            item.price
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                      ]
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.programasSelected,
                      callback: function($$v) {
                        _vm.programasSelected = $$v
                      },
                      expression: "programasSelected"
                    }
                  })
                }),
                1
              ),
              !_vm.programas.length
                ? _c("p", { staticClass: "my-2" }, [
                    _vm._v("No result for Programs")
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "outline-danger" },
                  on: { click: _vm.resetCheck }
                },
                [_vm._v("Reset")]
              ),
              _c(
                "b-card-text",
                { staticClass: "text-right" },
                [
                  _vm.createNewRequest
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.modal-all-programs",
                              modifiers: { "modal-all-programs": true }
                            }
                          ],
                          staticClass: "btn-blue-dark-outline",
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.handleModal }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.AdminPermisions.buttonShow")
                              ) +
                              " + "
                          )
                        ]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "btn-blue-dark-outline",
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.detailsRequest }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.AdminPermisions.statusRequest")
                              ) +
                              " + "
                          )
                        ]
                      )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-all-programs",
            title: this.$i18n.t("proposal.AdminPermisions.title"),
            "no-close-on-backdrop": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "div",
                    { staticClass: "w-100 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.modal-request",
                              modifiers: { "modal-request": true }
                            }
                          ],
                          staticClass: "blue-dark",
                          attrs: { size: "sm", disabled: !_vm.isValid },
                          on: { click: _vm.sendRequest }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.AdminPermisions.buttonSend")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "secundary", size: "sm" },
                          on: {
                            click: function($event) {
                              return cancel()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.AdminPermisions.buttonCancel")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("proposal.AdminPermisions.text")) + " ")
          ]),
          _c("b-form-group", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var ariaDescribedby = ref.ariaDescribedby
                  return [
                    _c("b-form-checkbox-group", {
                      staticClass: "custom-check-input",
                      attrs: {
                        id: "checkbox-programs",
                        options: _vm.optionsAllPrograms,
                        "aria-describedby": ariaDescribedby,
                        name: "allProgramas"
                      },
                      model: {
                        value: _vm.allProgramsSelected,
                        callback: function($$v) {
                          _vm.allProgramsSelected = $$v
                        },
                        expression: "allProgramsSelected"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("p", { staticClass: "my-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("proposal.AdminPermisions.justify")) + " "
            )
          ]),
          _c("b-form-textarea", {
            attrs: {
              placeholder: this.$i18n.t(
                "proposal.AdminPermisions.justifyPlaceholder"
              ),
              rows: "3",
              "max-rows": "6",
              state: _vm.textJustify.length >= 10
            },
            model: {
              value: _vm.textJustify,
              callback: function($$v) {
                _vm.textJustify = $$v
              },
              expression: "textJustify"
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-request",
          staticClass: "text-center",
          attrs: { id: "modal-request", "no-close-on-backdrop": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [_c("h5")]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "secundary", size: "sm" },
                          on: { click: _vm.cancelRequest }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("proposal.AdminPermisions.close")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "custom-row px-4" },
            [
              _c("h4", { staticClass: "my-2" }, [
                _vm._v(_vm._s(_vm.$t("proposal.AdminPermisions.wait")))
              ]),
              _c("p", { staticClass: "mb-5" }, [
                _vm._v(_vm._s(_vm.$t("proposal.AdminPermisions.process")))
              ]),
              _c(
                "b-iconstack",
                { attrs: { "font-scale": "5", animation: "spin" } },
                [
                  _c("b-icon", {
                    attrs: {
                      stacked: "",
                      icon: "arrow-clockwise",
                      animation: "throb",
                      variant: "secondary",
                      scale: "1"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }