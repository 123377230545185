var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "proposal__page" },
    [
      _c("Owner"),
      _c("NavbarInfo"),
      _c(
        "b-row",
        { staticClass: "text-center", attrs: { "align-v": "center" } },
        [
          _c("h3", { staticClass: "title mr-5" }, [
            _vm._v(_vm._s(_vm.$t("proposal.Title")))
          ]),
          _c("NoProposal")
        ],
        1
      ),
      !_vm.isLoadingCards
        ? _c(
            "b-row",
            { staticClass: "text-center", attrs: { "align-v": "start" } },
            [
              _vm.configRol.sspta.pestana.programs &&
              ((_vm.country === "MX" && _vm.becConfig.length) ||
                (_vm.country === "US" && _vm.becConfig.length > 0))
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Programs", {
                            attrs: {
                              coverageSelected: _vm.coverageSelected,
                              dataProducts: _vm.productsMxBec,
                              dataAllProducts: _vm.productsMxAllBec,
                              isLoadingProducts: _vm.isLoading,
                              becConfig: _vm.becConfig,
                              proposalingen: _vm.proposalingen
                            },
                            on: {
                              resultCoverage: _vm.dataCoverage,
                              resultPrograms: _vm.dataPrograms
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.configRol.sspta.pestana.technics &&
              _vm.techniquesConfig.length
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Techniques", {
                            attrs: {
                              dataProducts: _vm.productsMxTechniques,
                              isLoadingProducts: _vm.isLoading,
                              topProducts: _vm.productTop.TECHNICS || null,
                              techniquesConfig: _vm.techniquesConfig,
                              proposalingen: _vm.proposalingen
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.programasSelect
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Coverage", {
                            attrs: {
                              programasSelected: _vm.programasSelected,
                              coverageConfig: _vm.coverageConfig,
                              proposalingen: _vm.proposalingen
                            },
                            on: { resultCoverage: _vm.dataCoverage }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.configRol.sspta.pestana.labs &&
              ((_vm.country === "MX" && _vm.labsConfig.length) ||
                (_vm.country === "US" && _vm.includesLabs))
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Laboratory", {
                            attrs: {
                              dataProducts: _vm.productsMxLabs,
                              isLoadingProducts: _vm.isLoading,
                              topProducts:
                                _vm.productTop.LABS ||
                                _vm.productTop["LAB.GRAL"] ||
                                null,
                              labsConfig: _vm.labsConfig,
                              proposalingen: _vm.proposalingen
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.configRol.sspta.pestana.surgeries &&
              ((_vm.country === "MX" && _vm.surgeriesConfig.length) ||
                (_vm.country === "US" && _vm.includesSurgeries))
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Surgery", {
                            attrs: {
                              dataProducts: _vm.productsMxSurgery,
                              isLoadingProducts: _vm.isLoading,
                              topProducts:
                                _vm.productTop.SURGERIES ||
                                _vm.productTop.CIRUGIA ||
                                null,
                              surgeriesConfig: _vm.surgeriesConfig,
                              proposalingen: _vm.proposalingen
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.country !== "MX" && _vm.includesTreatment
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("Treatment", {
                            attrs: {
                              dataProducts: _vm.productsUsTreatment,
                              isLoadingProducts: _vm.isLoading,
                              topProducts: _vm.productTop.TREATMENT || null,
                              treatmentConfig: _vm.treatmentConfig,
                              proposalingen: _vm.proposalingen
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.country !== "MX" && _vm.includesFirstAppointment
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-card-group",
                        { attrs: { deck: "" } },
                        [
                          _c("FirstAppointment", {
                            attrs: {
                              dataProducts: _vm.productsUsFirstAppointment,
                              isLoadingProducts: _vm.isLoading,
                              topProducts:
                                _vm.productTop["FIRST APPOINTMENT"] || null,
                              FirstAppointmentConfig:
                                _vm.FirstAppointmentConfig,
                              proposalingen: _vm.proposalingen
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.othersCategory, function(category, idx) {
                return [
                  _vm.configRol.sspta.pestana[category[0].Categoria] ||
                  _vm.superAdmin
                    ? _c(
                        "b-col",
                        {
                          key: "category " + idx,
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-card-group",
                            { attrs: { deck: "" } },
                            [
                              _c("CardCategory", {
                                attrs: {
                                  isLoadingProducts: _vm.isLoading,
                                  categoryConfig: category,
                                  nameCategory: category[0].Categoria,
                                  proposalingen: _vm.proposalingen
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _c(
            "b-row",
            { staticClass: "justify-content-center my-4" },
            [
              _c("b-spinner", {
                attrs: { variant: "primary", label: "Text Centered" }
              })
            ],
            1
          ),
      _vm.configRol.sspta.pestana.medicinesIndividual ||
      _vm.configRol.sspta.pestana.medicinesPackage
        ? _c(
            "b-row",
            { attrs: { "align-v": "start" } },
            [
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12", md: "6" } },
                [_c("SelectPharmacy")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.configRol.sspta.pestana.medicinesPackage
        ? _c(
            "b-row",
            { attrs: { "align-v": "start" } },
            [_c("b-col", { staticClass: "p-0" }, [_c("FilterMedicine")], 1)],
            1
          )
        : _vm._e(),
      _vm.configRol.sspta.pestana.medicinesPackage
        ? _c(
            "b-row",
            { staticClass: "text-center", attrs: { "align-v": "start" } },
            [_c("b-col", [_c("PackageMedicine")], 1)],
            1
          )
        : _vm._e(),
      _vm.configRol.sspta.pestana.medicinesIndividual
        ? _c(
            "b-row",
            { staticClass: "text-center", attrs: { "align-v": "start" } },
            [_c("b-col", [_c("IndividualMedicine")], 1)],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "bottomRow" },
        [
          _c(
            "b-navbar",
            { staticClass: "fixedNavbar mb-3", attrs: { fixed: "bottom" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-blue-dark",
                  attrs: { to: "/recipe/sspta" }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("proposal.Go")))]),
                  _c("b-icon", {
                    staticClass: "icon",
                    attrs: { icon: "arrow-right", "aria-hidden": "true" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }