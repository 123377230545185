<template>

    <b-card
  
      :header="(this.FirstAppointmentConfig[0] && ( country === 'MX'? this.FirstAppointmentConfig[0].label_category: this.$i18n.t('proposal.FirstAppointment'))) || this.$i18n.t('proposal.FirstAppointment')"
      class="text-center"
  
      v-if="typeCard === 'Multiple'"
    >
  
      <div class="text-center" v-if="isLoading || isLoadingProducts">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <b-card-text v-else>
        <div class="search-product">
          <div class="groupSearch mb-3">
            <b-input-group size="sm" class="inputSearch">
              <b-form-input
                id="search-first-appointment"
                v-model="filterOptions"
                type="search"
                :placeholder="this.$i18n.t('welcome.search')"
                class="custom-input"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <hr />
          <div class="title-items">
            <span>{{ $t('proposal.Article') }}</span>
            <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
          </div>
        </div>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-first-appointment"
            v-model="FirstAppointmentSelected"
            :aria-describedby="ariaDescribedby"
            name="FirstAppointment"
            stacked
          >
            <b-form-checkbox
              v-for="(item, index) in FirstAppointments"
              :value="item.value"
              :key="'FirstAppointments-' + index"
            >
              <template v-if="item.top">
                <b class="b-ingen"><span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                  <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                    {{ item.text }}
                      <b
                      ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                  </b>
                  <hr v-if="amountTop - 1 === index" /> 
                      
                  </label>
                </span>
                
                <span v-else class="no-ingen">
                  {{item.text}} <b
                    ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                  </b> 
                </span></b>   
              </template>
              <template v-else>
                <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                  <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                    {{item.text}}<b
                      ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                    </b>
                  </label>
                </span>
                <span v-else class="no-ingen">
                  {{item.text}}<b
                    ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                  </b>
                </span>
              </template>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <p v-if="!FirstAppointments.length" class="my-2">No result for first appointments</p>
      </b-card-text>
    </b-card>
  
    <b-card
      :header="(this.FirstAppointmentConfig[0] && ( country === 'MX'? this.FirstAppointmentConfig[0].label_category: this.$i18n.t('proposal.FirstAppointment'))) || this.$i18n.t('proposal.FirstAppointment')"
      class="text-center"
      @change="getRadioValues"
      v-else
    >
      <div class="text-center" v-if="isLoading || isLoadingProducts">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <b-card-text v-else>
        <div class="search-product">
          <div class="groupSearch mb-3">
            <b-input-group size="sm" class="inputSearch">
              <b-form-input
                id="search-first-appointment"
                v-model="filterOptions"
                type="search"
                :placeholder="this.$i18n.t('welcome.search')"
                class="custom-input"
              >
              </b-form-input>
            </b-input-group>
          </div>
  
          <hr />
        </div>
        <b-form-radio-group
          v-model="FirstAppointmentSelected"
          v-slot="{ ariaDescribedby }"
          v-for="(item, index) in FirstAppointments"
          :key="index"
        >
          <b-form-radio
            :aria-describedby="ariaDescribedby"
            :name="item.category"
            :value="item"
          >
             <template v-if="item.top">
                <b>{{ item.text }} <hr v-if="amountTop - 1 === index"/> </b><b><span class="text-secondary">{{formatPrice(item.value.PrecioLista)}}</span> </b></template
              >
            <template v-else>{{ item.text }}<b><span class="text-primary ml-3">Precio: </span> {{formatPrice(item.value.monedaPrecio, item.value.PrecioLista)}}</b></template>
          </b-form-radio>
        </b-form-radio-group>
        <p v-if="!FirstAppointments.length" class="my-2">No result for first appointments</p>
        <b-button size="sm" variant="outline-danger" @click="resetCheck"
          >Reset</b-button
        >
      </b-card-text>
    </b-card>
  </template>
  
  <script>
  import { AlertForm, ICON } from "../../helpers/Alert";
  import { NumberFormat } from '../../helpers/NumberFormat';
  import { ApiRequest } from '../../helpers/ApiRequest';
  import { API_PRODUCTO_CATEGORIA } from '../../Constants/Api';
  
  
  export default {
    name: "FirstAppointmentSection",
    props: [
      "dataProducts",
      "isLoadingProducts",
      "topProducts",
      "FirstAppointmentConfig", 
      'proposalingen'
    ],
    data() {
      return {
        FirstAppointmentSelected: [],
        FirstAppointments: [],
        allFirstAppointments: [],
        country: "",
        listArticles: [],
        filterOptions: "",
        isLoading: false,
        amountTop: null,
        typeCard: "Multiple",
      };
    },
  
    async mounted() {
      this.country = sessionStorage.getItem("country");
      this.listArticles =
        JSON.parse(sessionStorage.getItem("arregloArticulos")) || [];
      await this.updateFirstAppointments();
      await this.propuestaIngenesFirstAppointment();
    },
    watch: {
      FirstAppointmentSelected() {
        
          let arrayArticles =
          JSON.parse(sessionStorage.getItem("arregloArticulos")) || [];
  
          arrayArticles = arrayArticles.filter(
            (el) => !["FIRST APPOINTMENT"].includes(el.name)
          );
          if(this.FirstAppointmentSelected.length) {
            if (this.typeCard === "Multiple") {
              this.FirstAppointmentSelected.forEach((el) => {
                arrayArticles.push({
                  name: el.Categoria,
                  value: el.Articulo,
                  amount: "1",
                  description: el.CodigoAlterno,
                  price: el.PrecioLista.toString(),
                  currencyFormat: el.monedaPrecio,
                  data: el,
                });
              });
            }
          }
          sessionStorage.setItem("arregloArticulos", JSON.stringify(arrayArticles));
        
      },
      async dataProducts() {
        await this.updateFirstAppointments();
      },
      async topProducts() {
        await this.updateFirstAppointments();
      },
      async FirstAppointmentConfig() {
        setTimeout(() => {
          this.updateFirstAppointments();
        }, 2000);
      },
      filterOptions() {
        this.FirstAppointments = this.allFirstAppointments.filter((el) =>
          el.text.toLowerCase().includes(this.filterOptions.toLowerCase())
        );
        this.amountTop = this.FirstAppointments.filter((el) => el.top === true).length;
      },
    },
    methods: {
      
  
      async updateFirstAppointments() {
        try {
          let dataFirstAppointment = [];
          this.isLoading = true;
          if (this.FirstAppointmentConfig.length) {
            dataFirstAppointment = this.FirstAppointmentConfig;
            this.typeCard = this.FirstAppointmentConfig[0].tipocard;
          } else {
            if (this.topProducts !== null) {
              dataFirstAppointment = this.topProducts;
            } else if (this.country === 'US') {
              let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}FIRST APPOINTMENT`);
              dataFirstAppointment = data.productos;
            } else {
              dataFirstAppointment = this.dataProducts;
            }
          }
  
          let cantidad = 0;
          this.FirstAppointments = dataFirstAppointment.map((el) => {
            if (el.isTop) cantidad++;
            return {
              text: el.CodigoAlterno,
              value: el,
              top: el.isTop ? true : false,
            };
          });
          this.amountTop = cantidad;
          this.allFirstAppointments = this.FirstAppointments;
          // let filterFirstAppointment = this.listArticles.filter((el) => ["TREATMENT"].includes(el.name));
          let filterFirstAppointment = this.listArticles.filter((el) => el.name === 'FIRST APPOINTMENT');
  
  
          filterFirstAppointment = filterFirstAppointment.map((el) => {
            return el.description;
          });
  
          filterFirstAppointment = this.FirstAppointments.filter((el) => filterFirstAppointment.includes(el.text));
  
          this.FirstAppointmentSelected = filterFirstAppointment.map((el) => el.value);
          if (this.typeCard === 'Unico' && this.FirstAppointmentSelected.length) {
            this.FirstAppointmentSelected = {
              text: this.FirstAppointmentSelected[0].CodigoAlterno,
              top: this.FirstAppointmentSelected[0].isTop,
              value: this.FirstAppointmentSelected[0],
            };
          }
        } catch (err) {
          AlertForm(`Error Code: ${err.status || ''}`, err.statusText, ICON.ERROR);
        } finally {
          this.isLoading = false;
        }
      },
      getRadioValues() {
        const arrayArticles = JSON.parse(
          sessionStorage.getItem("arregloArticulos")
        );
        //const filterFirstAppointment = arrayArticles.filter((el) => !['TREATMENT'].includes(el.name));
        const filterFirstAppointment = arrayArticles.filter((el) => el.name !== 'FIRST APPOINTMENT');
  
        let dataSelected = {
          amount: "1",
          currencyFormat: this.FirstAppointmentSelected.value.monedaPrecio,
          description: this.FirstAppointmentSelected.text,
          name: this.FirstAppointmentSelected.value.Categoria,
          price: this.FirstAppointmentSelected.value.PrecioLista,
          top: this.FirstAppointmentSelected.top,
          value: this.FirstAppointmentSelected.value.Articulo,
          data: this.FirstAppointmentSelected.value,
        };
        filterFirstAppointment.push(dataSelected);
        sessionStorage.setItem("arregloArticulos", JSON.stringify(filterFirstAppointment));
      },
      resetCheck() {
        this.FirstAppointmentSelected = "";
  
        const arregloArticulos = JSON.parse(
          sessionStorage.getItem("arregloArticulos")
        );
        sessionStorage.setItem(
          "arregloArticulos",
          JSON.stringify(arregloArticulos.filter((el) => el.name !== "FIRST APPOINTMENT"))
        );
      },
      formatPrice(price) {
        return NumberFormat(price);
      },
      propuestaIngenesFirstAppointment(){
        if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
          if (this.proposalingen.msg.length) {
            this.FirstAppointments.forEach(item => {
              let laboratoryIngen = this.proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria)  
              if(laboratoryIngen != null){
                let exist = this.FirstAppointmentSelected.find(x => x.CodigoAlterno == laboratoryIngen.cfgd_articulo && x.Categoria == laboratoryIngen.cfgd_Categoria);
                if(exist == null || exist == undefined){
                  this.FirstAppointmentSelected.push(item.value);
                }    
              }
            }); 
          }
        }
      }
    },
  };
  </script>
  
  <style scoped>
  @import "../../assets/css/global.css";
  @import "../../assets/css/proposal.css";
  
  .groupSearch {
    width: 50%;
  }
  </style>
  