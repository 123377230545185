var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card__custom" },
    [
      _c(
        "b-row",
        { staticClass: "m-3 align-items-center justify-content-center" },
        [
          _c("b-col", [
            _c("strong", [_vm._v(_vm._s(_vm.$t("medicine.cycle")))]),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "m-md-2 dropdown__custom",
                    attrs: {
                      id: "dropdown-right-1",
                      text: _vm.selectCycle.label,
                      variant: "outline-white"
                    }
                  },
                  [
                    _c("div", { staticClass: "header__dropdown" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("medicine.cycle")) + ":")])
                    ]),
                    _vm._l(_vm.dataCycles, function(item, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          staticClass: "item__dropdown",
                          on: {
                            click: function($event) {
                              return _vm.handleCycle(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c("b-col", [
            _c("strong", [_vm._v(_vm._s(_vm.$t("medicine.dosage")))]),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "m-md-2 dropdown__custom",
                    attrs: {
                      id: "dropdown-right-3",
                      text: _vm.selectDosage.label,
                      variant: "outline-white"
                    }
                  },
                  [
                    _c("div", { staticClass: "header__dropdown" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("medicine.dosage")) + ": "),
                        _vm.selectCycle.label ===
                        "---" + this.$i18n.t("medicine.select") + "---"
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$t("medicine.msgDosage")))
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._l(_vm.dataDosage, function(item, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          staticClass: "item__dropdown",
                          on: {
                            click: function($event) {
                              return _vm.handleDosage(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c("b-col", [
            _c("strong", [_vm._v(_vm._s(_vm.$t("medicine.medicine")))]),
            _c(
              "div",
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "m-md-2 dropdown__custom",
                    attrs: {
                      id: "dropdown-right",
                      text: _vm.selectMedicine.label,
                      variant: "outline-white"
                    }
                  },
                  [
                    _c("div", { staticClass: "header__dropdown" }, [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("medicine.medicine")) + ": "
                        ),
                        _vm.selectDosage.label ===
                        "---" + this.$i18n.t("medicine.select") + "---"
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$t("medicine.msgMedicine")))
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._l(_vm.dataMedicine, function(item, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          staticClass: "item__dropdown",
                          on: {
                            click: function($event) {
                              return _vm.handleMedicine(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "b-col",
            { attrs: { sm: "3" } },
            [
              !_vm.isValid
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.focus.html.v-danger",
                          value: _vm.popoverText,
                          expression: "popoverText",
                          modifiers: {
                            focus: true,
                            html: true,
                            "v-danger": true
                          }
                        }
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        pill: "",
                        variant: "success",
                        title: _vm.titleValid
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "check2", "aria-hidden": "true" }
                      }),
                      _vm._v(_vm._s(_vm.$t("medicine.apply")))
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "mr-3",
                      attrs: { pill: "", variant: "success" },
                      on: { click: _vm.handleSearchMedicine }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "check2", "aria-hidden": "true" }
                      }),
                      _vm._v(_vm._s(_vm.$t("medicine.apply")))
                    ],
                    1
                  ),
              _c(
                "b-button",
                {
                  attrs: { pill: "", variant: "outline-secondary" },
                  on: { click: _vm.deleteFields }
                },
                [_vm._v(_vm._s(_vm.$t("medicine.delete")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }