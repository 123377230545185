var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-group",
    { attrs: { deck: "" } },
    [
      _c(
        "b-card",
        {
          staticClass: "text-center tableMedicine",
          attrs: { header: this.$i18n.t("proposal.Medicine.titlePackage") }
        },
        [
          _c(
            "b-card-text",
            { staticClass: "mt-3 ml-4 tableMedicineItem" },
            [
              !_vm.items.length && !_vm.loadingPackage
                ? _c("p", [_vm._v(_vm._s(_vm.message))])
                : _vm._e(),
              _vm.loadingPackage
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-spinner", {
                        attrs: { variant: "primary", label: "Text Centered" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.items, function(item) {
                return _c(
                  "div",
                  { key: item.Articulo, staticClass: "mt-4" },
                  [
                    _c("h6", { staticClass: "border-bottom" }, [
                      _vm._v(_vm._s(item.CodigoAlterno))
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("h6", [
                          _vm._v(
                            _vm._s(_vm.$t("proposal.Medicine.MSI")) +
                              ": " +
                              _vm._s(_vm.format(item.PrecioLista))
                          )
                        ]),
                        _c("h6", [
                          _vm._v(
                            _vm._s(_vm.$t("proposal.Medicine.Cash")) +
                              ": " +
                              _vm._s(_vm.format(item.Precio2))
                          )
                        ]),
                        _c("h6", [
                          _vm._v(
                            _vm._s(_vm.$t("proposal.Medicine.Cash2")) +
                              ": " +
                              _vm._s(_vm.format(item.Precio3))
                          )
                        ])
                      ]
                    ),
                    _c("b-table", {
                      staticClass: "mt-4 table-package-medicine",
                      attrs: {
                        items: item.Componentes,
                        fields: _vm.fields,
                        filter: _vm.filter,
                        "filter-included-fields": _vm.filterOn,
                        "thead-tr-class": "headerClass",
                        "sort-icon-left": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(CodigoAlterno)",
                            fn: function(data) {
                              return [_c("b", [_vm._v(_vm._s(data.value))])]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _vm.validationButton
                        ? _c(
                            "button",
                            {
                              staticClass: "card-country",
                              class: {
                                active: _vm.activeButton[item.Articulo]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.formaPago(item.Articulo)
                                }
                              }
                            },
                            [
                              _vm.activeButton[item.Articulo]
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mx-3",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "check-square",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "mx-3",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "square",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.$t("medicine.table.select"))
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }