<template>

  <b-card
    :header="
    (this.surgeriesConfig[0] && ( country === 'MX'? this.surgeriesConfig[0].label_category: this.$i18n.t('proposal.Surgery'))) || 
    this.$i18n.t('proposal.Surgery')
    "
    class="text-center"
    v-if="typeCard === 'Multiple'"
  >

    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-product">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-surgery"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>
        <hr />
        <div class="title-items">
          <span>{{ $t('proposal.Article') }}</span>
          <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
        </div>
      </div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-surgery"
          v-model="surgerySelected"
          :aria-describedby="ariaDescribedby"
          name="surgery"
          stacked
        >
          <b-form-checkbox
            v-for="(item, index) in surgeries"
            :value="item.value"
            :key="'surgeries-' + index"
          >
            <template v-if="item.top">
              <b class="b-ingen"><span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                  {{ item.text }}
                    <b
                    ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b>
                <hr v-if="amountTop - 1 === index" /> 
                    
                </label>
              </span>
              
              <span v-else class="no-ingen">
                {{item.text}} <b
                  ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b> 
              </span></b>   
            </template>
            <template v-else>
              <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                  {{item.text}}<b
                    ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                  </b>
                </label>
              </span>
              <span v-else class="no-ingen">
                {{item.text}}<b
                  ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b>
              </span>
            </template>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <p v-if="!surgeries.length" class="my-2">No result for Surgeries</p>
    </b-card-text>
  </b-card>
  <!-- <b-card
    :header="
      (this.surgeriesConfig[0] && this.surgeriesConfig[0].label_category) ||
      this.$i18n.t('proposal.Surgery')
    "
    class="text-center"
    @change="getRadioValues"
    v-else
  > -->
  <b-card
    :header="
    (this.surgeriesConfig[0] && ( country === 'MX'? this.surgeriesConfig[0].label_category: this.$i18n.t('proposal.Surgery'))) || 
    this.$i18n.t('proposal.Surgery')
    "
    class="text-center"
    @change="getRadioValues"
    v-else
  >
    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-product">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-technics"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>

        <hr />
      </div>
      <b-form-radio-group
        v-model="surgerySelected"
        v-slot="{ ariaDescribedby }"
        v-for="(item, index) in surgeries"
        :key="index"
      >
        <b-form-radio
          :aria-describedby="ariaDescribedby"
          :name="item.category"
          :value="item"
        >
           <template v-if="item.top">
              <b>{{ item.text }} <hr v-if="amountTop - 1 === index"/> </b><b><span class="text-secondary">{{formatPrice(item.value.PrecioLista)}}</span> </b></template
            >
          <template v-else>{{ item.text }}<b><span class="text-primary ml-3">Precio: </span> {{formatPrice(item.value.monedaPrecio, item.value.PrecioLista)}}</b></template>
        </b-form-radio>
      </b-form-radio-group>
      <p v-if="!surgeries.length" class="my-2">No result for Surgeries</p>
      <b-button size="sm" variant="outline-danger" @click="resetCheck"
        >Reset</b-button
      >
    </b-card-text>
  </b-card>
</template>

<script>
import { AlertForm, ICON } from "../../helpers/Alert";
import { NumberFormat } from '../../helpers/NumberFormat';
import { ApiRequest } from '../../helpers/ApiRequest';
import { API_PRODUCTO_CATEGORIA } from '../../Constants/Api';


export default {
  name: "SurgerySection",
  props: [
    "dataProducts",
    "isLoadingProducts",
    "topProducts",
    "surgeriesConfig", 
    'proposalingen'
  ],
  data() {
    return {
      surgerySelected: [],
      surgeries: [],
      allSurgeries: [],
      country: "",
      listArticles: [],
      filterOptions: "",
      isLoading: false,
      amountTop: null,
      typeCard: "Multiple",
    };
  },

  async mounted() {
    this.country = sessionStorage.getItem("country");
    this.listArticles =
      JSON.parse(sessionStorage.getItem("arregloArticulos")) || [];
    await this.updateSurgeries();
    await this.propuestaIngenesCirugias();
  },
  watch: {
    surgerySelected() {
      
        let arrayArticles =
        JSON.parse(sessionStorage.getItem("arregloArticulos")) || [];

        arrayArticles = arrayArticles.filter(
          (el) => !["SURGERIES", "CIRUGIA"].includes(el.name)
        );
        if(this.surgerySelected.length) {
          if (this.typeCard === "Multiple") {
            this.surgerySelected.forEach((el) => {
              arrayArticles.push({
                name: el.Categoria,
                value: el.Articulo,
                amount: "1",
                description: el.CodigoAlterno,
                price: el.PrecioLista.toString(),
                currencyFormat: el.monedaPrecio,
                data: el,
              });
            });
          }
        }
        sessionStorage.setItem("arregloArticulos", JSON.stringify(arrayArticles));
      
    },
    async dataProducts() {
      await this.updateSurgeries();
    },
    async topProducts() {
      await this.updateSurgeries();
    },
    async surgeriesConfig() {
      setTimeout(() => {
        this.updateSurgeries();
      }, 2000);
    },
    filterOptions() {
      this.surgeries = this.allSurgeries.filter((el) =>
        el.text.toLowerCase().includes(this.filterOptions.toLowerCase())
      );
      this.amountTop = this.surgeries.filter((el) => el.top === true).length;
    },
  },
  methods: {
    
    async updateSurgeries() {
      try {
        let dataSurgery = [];
        this.isLoading = true;
        if (this.surgeriesConfig.length) {
          dataSurgery = this.surgeriesConfig;
          this.typeCard = this.surgeriesConfig[0].tipocard;
        } else {
          if (this.topProducts !== null) {
            dataSurgery = this.topProducts;
          } else if (this.country === 'US') {
            let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}SURGERIES`);
            dataSurgery = data.productos;
          } else {
            dataSurgery = this.dataProducts;
          }
        }

        let cantidad = 0;
        this.surgeries = dataSurgery.map((el) => {
          if (el.isTop) cantidad++;
          return {
            text: el.CodigoAlterno,
            value: el,
            top: el.isTop ? true : false,
          };
        });
        this.amountTop = cantidad;
        this.allsurgeries = this.surgeries;
        let filterSurgery = this.listArticles.filter((el) => ["SURGERIES", "CIRUGIA"].includes(el.name));

        filterSurgery = filterSurgery.map((el) => {
          return el.description;
        });

        filterSurgery = this.surgeries.filter((el) => filterSurgery.includes(el.text));

        this.surgerySelected = filterSurgery.map((el) => el.value);
        if (this.typeCard === 'Unico' && this.surgerySelected.length) {
          this.surgerySelected = {
            text: this.surgerySelected[0].CodigoAlterno,
            top: this.surgerySelected[0].isTop,
            value: this.surgerySelected[0],
          };
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status || ''}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoading = false;
      }
    },
    getRadioValues() {
      const arrayArticles = JSON.parse(
        sessionStorage.getItem("arregloArticulos")
      );
      const filterSurgery = arrayArticles.filter((el) => !['SURGERIES', 'CIRUGIA'].includes(el.name));
      let dataSelected = {
        amount: "1",
        currencyFormat: this.surgerySelected.value.monedaPrecio,
        description: this.surgerySelected.text,
        name: this.surgerySelected.value.Categoria,
        price: this.surgerySelected.value.PrecioLista,
        top: this.surgerySelected.top,
        value: this.surgerySelected.value.Articulo,
        data: this.surgerySelected.value,
      };
      filterSurgery.push(dataSelected);
      sessionStorage.setItem("arregloArticulos", JSON.stringify(filterSurgery));
    },
    resetCheck() {
      this.surgerySelected = "";

      const arregloArticulos = JSON.parse(
        sessionStorage.getItem("arregloArticulos")
      );
      sessionStorage.setItem(
        "arregloArticulos",
        JSON.stringify(arregloArticulos.filter((el) => el.name !== "SURGERIES"))
      );
    },
    formatPrice(price) {
      return NumberFormat(price);
    },
    propuestaIngenesCirugias(){
      if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
        if (this.proposalingen.msg.length) {
          this.surgeries.forEach(item => {
            let laboratoryIngen = this.proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria)  
            if(laboratoryIngen != null){
              let exist = this.surgerySelected.find(x => x.CodigoAlterno == laboratoryIngen.cfgd_articulo && x.Categoria == laboratoryIngen.cfgd_Categoria);
              if(exist == null || exist == undefined){
                this.surgerySelected.push(item.value);
              }    
            }
          }); 
        }
      }
    }
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";
@import "../../assets/css/proposal.css";

.groupSearch {
  width: 50%;
}
</style>
