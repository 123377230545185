var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.typeCard === "Multiple"
    ? _c(
        "b-card",
        {
          staticClass: "text-center",
          attrs: {
            header:
              (this.treatmentConfig[0] &&
                (_vm.country === "MX"
                  ? this.treatmentConfig[0].label_category
                  : this.$i18n.t("proposal.Treatment"))) ||
              this.$i18n.t("proposal.Treatment")
          }
        },
        [
          _vm.isLoading || _vm.isLoadingProducts
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Text Centered" }
                  })
                ],
                1
              )
            : _c(
                "b-card-text",
                [
                  _c("div", { staticClass: "search-product" }, [
                    _c(
                      "div",
                      { staticClass: "groupSearch mb-3" },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "inputSearch", attrs: { size: "sm" } },
                          [
                            _c("b-form-input", {
                              staticClass: "custom-input",
                              attrs: {
                                id: "search-treatment",
                                type: "search",
                                placeholder: this.$i18n.t("welcome.search")
                              },
                              model: {
                                value: _vm.filterOptions,
                                callback: function($$v) {
                                  _vm.filterOptions = $$v
                                },
                                expression: "filterOptions"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "title-items" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("proposal.Article")))]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("proposal.Price")) +
                            " (" +
                            _vm._s(_vm.country === "MX" ? "MXN " : "USD ") +
                            ")"
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var ariaDescribedby = ref.ariaDescribedby
                            return [
                              _c(
                                "b-form-checkbox-group",
                                {
                                  attrs: {
                                    id: "checkbox-group-treatment",
                                    "aria-describedby": ariaDescribedby,
                                    name: "treatment",
                                    stacked: ""
                                  },
                                  model: {
                                    value: _vm.treatmentSelected,
                                    callback: function($$v) {
                                      _vm.treatmentSelected = $$v
                                    },
                                    expression: "treatmentSelected"
                                  }
                                },
                                _vm._l(_vm.treatments, function(item, index) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: "treatments-" + index,
                                      attrs: { value: item.value }
                                    },
                                    [
                                      item.top
                                        ? [
                                            _c(
                                              "b",
                                              { staticClass: "b-ingen" },
                                              [
                                                _vm.proposalingen.msg != null ||
                                                _vm.proposalingen.msg !=
                                                  undefined
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "no-ingen"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            class: {
                                                              "ingenes-class": _vm.proposalingen.msg.find(
                                                                function(x) {
                                                                  return (
                                                                    x.cfgd_articulo ==
                                                                      item.text &&
                                                                    x.cfgd_Categoria ==
                                                                      item.value
                                                                        .Categoria
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.text
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("b", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-secondary"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatPrice(
                                                                        item
                                                                          .value
                                                                          .PrecioLista
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]),
                                                            _vm.amountTop -
                                                              1 ===
                                                            index
                                                              ? _c("hr")
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "no-ingen"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                        _c("b", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-secondary"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    item.value
                                                                      .PrecioLista
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]
                                        : [
                                            _vm.proposalingen.msg != null ||
                                            _vm.proposalingen.msg != undefined
                                              ? _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        class: {
                                                          "ingenes-class": _vm.proposalingen.msg.find(
                                                            function(x) {
                                                              return (
                                                                x.cfgd_articulo ==
                                                                  item.text &&
                                                                x.cfgd_Categoria ==
                                                                  item.value
                                                                    .Categoria
                                                              )
                                                            }
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text)
                                                        ),
                                                        _c("b", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-secondary"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    item.value
                                                                      .PrecioLista
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(item.text)
                                                    ),
                                                    _c("b", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-secondary"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                item.value
                                                                  .PrecioLista
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                          ]
                                    ],
                                    2
                                  )
                                }),
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3771618051
                    )
                  }),
                  !_vm.treatments.length
                    ? _c("p", { staticClass: "my-2" }, [
                        _vm._v("No result for treatments")
                      ])
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    : _c(
        "b-card",
        {
          staticClass: "text-center",
          attrs: {
            header:
              (this.treatmentConfig[0] &&
                (_vm.country === "MX"
                  ? this.treatmentConfig[0].label_category
                  : this.$i18n.t("proposal.Treatment"))) ||
              this.$i18n.t("proposal.Treatment")
          },
          on: { change: _vm.getRadioValues }
        },
        [
          _vm.isLoading || _vm.isLoadingProducts
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Text Centered" }
                  })
                ],
                1
              )
            : _c(
                "b-card-text",
                [
                  _c("div", { staticClass: "search-product" }, [
                    _c(
                      "div",
                      { staticClass: "groupSearch mb-3" },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "inputSearch", attrs: { size: "sm" } },
                          [
                            _c("b-form-input", {
                              staticClass: "custom-input",
                              attrs: {
                                id: "search-treatment",
                                type: "search",
                                placeholder: this.$i18n.t("welcome.search")
                              },
                              model: {
                                value: _vm.filterOptions,
                                callback: function($$v) {
                                  _vm.filterOptions = $$v
                                },
                                expression: "filterOptions"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr")
                  ]),
                  _vm._l(_vm.treatments, function(item, index) {
                    return _c("b-form-radio-group", {
                      key: index,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var ariaDescribedby = ref.ariaDescribedby
                              return [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      "aria-describedby": ariaDescribedby,
                                      name: item.category,
                                      value: item
                                    }
                                  },
                                  [
                                    item.top
                                      ? [
                                          _c("b", [
                                            _vm._v(_vm._s(item.text) + " "),
                                            _vm.amountTop - 1 === index
                                              ? _c("hr")
                                              : _vm._e()
                                          ]),
                                          _c("b", [
                                            _c(
                                              "span",
                                              { staticClass: "text-secondary" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      item.value.PrecioLista
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      : [
                                          _vm._v(_vm._s(item.text)),
                                          _c("b", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-primary ml-3"
                                              },
                                              [_vm._v("Precio: ")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    item.value.monedaPrecio,
                                                    item.value.PrecioLista
                                                  )
                                                )
                                            )
                                          ])
                                        ]
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.treatmentSelected,
                        callback: function($$v) {
                          _vm.treatmentSelected = $$v
                        },
                        expression: "treatmentSelected"
                      }
                    })
                  }),
                  !_vm.treatments.length
                    ? _c("p", { staticClass: "my-2" }, [
                        _vm._v("No result for treatments")
                      ])
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-danger" },
                      on: { click: _vm.resetCheck }
                    },
                    [_vm._v("Reset")]
                  )
                ],
                2
              )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }