<template>
  <b-card-group deck>
    <b-card :header="this.$i18n.t('proposal.Medicine.titlePackage')" class="text-center tableMedicine">
      <b-card-text class="mt-3 ml-4 tableMedicineItem">
        <p v-if="!items.length && !loadingPackage">{{ message }}</p>
        <div class="text-center" v-if="loadingPackage">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <!-- PACKAGE table element -->
        <div v-for="item in items" :key="item.Articulo" class="mt-4">
          <h6 class="border-bottom">{{ item.CodigoAlterno }}</h6>
          <div class="d-flex justify-content-between">
            <h6>{{ $t('proposal.Medicine.MSI') }}: {{ format(item.PrecioLista) }}</h6>
            <h6>{{ $t('proposal.Medicine.Cash') }}: {{ format(item.Precio2) }}</h6>
            <h6>{{ $t('proposal.Medicine.Cash2') }}: {{ format(item.Precio3) }}</h6>
          </div>

          <b-table
            :items="item.Componentes"
            :fields="fields"
            class="mt-4 table-package-medicine"
            :filter="filter"
            :filter-included-fields="filterOn"
            thead-tr-class="headerClass"
            sort-icon-left
          >
            <template #cell(CodigoAlterno)="data">
              <b>{{ data.value }}</b>
            </template>
          </b-table>
          <div class="d-flex justify-content-end">
            <button
              class="card-country"
              :class="{ active: activeButton[item.Articulo] }"
              @click="formaPago(item.Articulo)"
              v-if="validationButton"
            >
              <span aria-hidden="true" class="mx-3" v-if="activeButton[item.Articulo]">
                <b-icon icon="check-square" aria-hidden="true"></b-icon>
              </span>
              <span aria-hidden="true" class="mx-3" v-else>
                <b-icon icon="square" aria-hidden="true"></b-icon>
              </span>
              <p>
                <b>{{ $t('medicine.table.select') }}</b>
              </p>
            </button>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </b-card-group>
</template>

<script>
import { API_ROOT } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import { NumberFormat } from '../../helpers/NumberFormat';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: 'PackageMedicine',
  data() {
    return {
      country: '',
      //table package
      items: [],
      packageSelected: [],
      filter: null,
      filterOn: [],
      isActive: null,
      pathName: '',
      activeButton: {},
      message: 'No information',
      loadingPackage: false,
    };
  },

  mounted() {
    this.country = sessionStorage.getItem('country');
    this.pathName = this.$route.path;
    let newObject = {};
    for (let i = 0; i < 18; i++) {
      newObject[i + 10000] = null;
    }
    this.isActive = newObject;
  },
  computed: {
    validationButton() {
      return this.pathName === '/proposal';
    },

    fields() {
      return [
        {
          key: 'CodigoAlterno',
          label: this.$i18n.t('medicine.table.medicine'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'Cantidad',
          label: this.$i18n.t('medicine.table.includes'),
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'actions', label: '' },
      ];
    },

    filterSelected() {
      return this.$store.state.filter_selected;
    },
  },
  watch: {
    async filterSelected() {
      try {
        this.loadingPackage = true;
        let response;
        if (this.filterSelected.medicine) {
          response = await ApiRequest.post(`${API_ROOT}agrupadorMedicamentos/claveMedPaqFiltros`, {
            ciclo: this.filterSelected.cycle,
            dosis: this.filterSelected.dosage,
            medicamento: this.filterSelected.medicine,
          });
        } else {
          response = await ApiRequest.post(`${API_ROOT}agrupadorMedicamentos/claveMedPaq`, {
            ciclo: this.filterSelected.cycle,
            dosis: this.filterSelected.dosage,
          });
        }
        const packageList = await ApiRequest.post(`${API_ROOT}filtrosMedicamentos/medicamentosPaquete`, {
          ...this.$store.state.pharmacy_selected.value,
          codigoint: response.lista.map((el) => el.trim()),
        });
        this.items = packageList.data;
        if (!packageList.data.length) this.message = 'No results found for the search filters';
      } catch (err) {
        return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.loadingPackage = false;
      }
    },
  },
  methods: {
    format(number) {
      return NumberFormat(number);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.isActive) return 'table-row';
    },
    formaPago(id) {
      let dataPrograms = JSON.parse(sessionStorage.getItem('arregloArticulos')).filter(
        (el) => el.name !== 'MEDICINA PAQUETE'
      );

      let filterPackage = this.items.find((el) => el.Articulo === id);
      dataPrograms.push({
        name: 'MEDICINA PAQUETE',
        price: filterPackage.PrecioLista,
        amount: this.country === 'US' ? '0' : '1',
        value: filterPackage.Articulo,
        description: filterPackage.CodigoAlterno,
        currencyFormat: filterPackage.monedaPrecio,
        data: filterPackage,
      });
      sessionStorage.setItem('arregloArticulos', JSON.stringify(dataPrograms));
      if (!this.activeButton[id]) {
        this.activeButton = {};
        this.activeButton[id] = true;
      } else {
        this.activeButton = {};
      }
    },
    handleFarmacia(item) {
      this.country === 'US' ? (this.farmaciaUS = item.label) : (this.farmaciaMX = item.label);
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

/**** DropDown **** */
.header__dropdown {
  display: flex;
  margin: 5px 10px;
  color: rgb(173, 181, 189);
}
.header__dropdown > img {
  padding-right: 6px;
}
.item__dropdown {
  padding: 0 0.5rem 0 1rem;
  font-size: 0.9rem;
}

/**table */
.table-package-medicine /deep/ .headerClass {
  color: var(--gray-color);
  border: none;
}

/** button price table */
.country-group {
  padding: 20px 20px 20px 50px;
}

.card-country {
  height: 60px;
  min-width: 220px;
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
}

.align-end {
  text-align: end;
}
</style>
