<template>
  <b-card
    :header="(this.techniquesConfig[0] && ( country === 'MX'? this.techniquesConfig[0].label_category: this.$i18n.t('proposal.Techniques'))) || this.$i18n.t('proposal.Techniques')"
    class="text-center"
    @change="getCheckboxValues"
  >
    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-product">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-technics"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>

        <hr />
        <div class="title-items">
          <span>{{ $t('proposal.Article') }}</span>
          <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
        </div>
      </div>
      <!-- <b-form-radio-group
        v-model="techniquesSelected"
        v-slot="{ ariaDescribedby }"
        v-for="(item, index) in techniques"
        :key="index"
      > -->
      <b-form-checkbox-group
          v-model="techniquesSelected"
          v-slot="{ ariaDescribedby }"
          v-for="(item, index) in techniques"
          :key="index"
          stacked
        >
         <b-form-checkbox :aria-describedby="ariaDescribedby" :name="item.category" :value="item">

          <!--
          <template v-if="item.top">
            <b>{{ item.description }}</b></template
          >
          <template v-else>{{ item.description }}</template>
          -->
          <template v-if="item.top">
            <!-- <b>{{ item.description }}
              <hr v-if="amountTop - 1 === index" /> </b>
              <b><span class="text-secondary">{{ formatPrice(item.price) }}</span></b> -->
              <b class="b-ingen"><span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.value && x.cfgd_Categoria == item.data.Categoria) }">
                  {{ item.description }}
                    <b
                    ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
                </b>
                <hr v-if="amountTop - 1 === index" /> 
                    
                </label>
              </span>
              <span v-else class="no-ingen">
                {{item.description}} <b
                  ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
                </b> 
              </span></b> 
          </template>
          <template v-else>
            <!-- {{ item.description}}<b>
              <span class="text-secondary">{{ formatPrice(item.price) }}</span>
            </b> -->
            <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen">
                <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.value && x.cfgd_Categoria == item.data.Categoria) }" >
                  {{ item.description}}
                  <b><span class="text-secondary">{{ formatPrice(item.price) }}</span></b>
                </label>
            </span>
            <span v-else class="no-ingen">
              {{item.description}}<b
                ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
              </b>
            </span>
          </template
          >
        </b-form-checkbox>
        <template v-if="amountTop - 1 === index"> <hr /></template>
      </b-form-checkbox-group>
      <p v-if="!techniques.length" class="my-2">No result for techniques</p>
      <b-button size="sm" variant="outline-danger" @click="resetCheck">Reset</b-button>
    </b-card-text>
  </b-card>
</template>


<script>
import { API_PRODUCTO_CATEGORIA } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';
import { NumberFormat } from '../../helpers/NumberFormat';

export default {
  name: 'TechniquesSection',
  props: ['dataProducts', 'isLoadingProducts', 'topProducts', 'techniquesConfig', 'proposalingen'],
  data() {
    return {
      techniquesSelected: [],
      techniques: [],
      allTechniques: [],
      country: '',
      listArticles: [],
      isLoading: false,
      filterOptions: '',
      amountTop: null,
    };
  },

  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.listArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || [];
    await this.updateTechnics();
    await this.propuestaIngenesTechnics();
  },
  watch: {
    async dataProducts() {
      await this.updateTechnics();
      await this.propuestaIngenesTechnics();
    },
    async topProducts() {
      await this.updateTechnics();
    },
    async techniquesConfig() {
      setTimeout(() => {
        this.updateTechnics();
      }, 2000);
    },
    filterOptions() {
      this.techniques = this.allTechniques.filter((item) =>
        item.description.toLowerCase().includes(this.filterOptions.toLowerCase())
      );
      this.amountTop = this.techniques.filter((item) => item.top === true).length;
    },
  },
  methods: {
    isChecked(name, value) {
      let validacion = this.listArticles.filter((item) => item.name === name && item.value.includes(value));
      return validacion.length === 1;
    },
    async updateTechnics() {
      
      try {
        let dataTech = [];
        this.isLoading = true;
        if (this.techniquesConfig.length) {
          dataTech = this.techniquesConfig;
        } else {
          if (this.topProducts !== null) {
            dataTech = this.topProducts;
          } else if (this.country === 'US') {
            let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}TECHNICS`);
            dataTech = data.productos;
          } else {
            dataTech = this.dataProducts;
          }
        }

        let cantidad = 0;
        const dataArray = dataTech.map((item) => {
          if (item.isTop) cantidad++;
          return {
            value: item.Articulo,
            price: item.PrecioLista,
            name: item.Categoria,
            description: item.CodigoAlterno,
            currencyFormat: item.monedaPrecio,
            amount: '1',
            top: item.isTop ? true : false,
            data: item,
          };
        });

        let filterTechnics = this.listArticles.filter((item) => item.name === 'TECHNICS');
        filterTechnics = filterTechnics.map(item => item.value)

        this.techniquesSelected = 
          filterTechnics.length 
          ? dataArray.filter((item) => filterTechnics.includes(item.value) ) 
          : [];        
        this.amountTop = cantidad;
        this.techniques = dataArray;
        this.allTechniques = dataArray;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoading = false;
      }
    },
    resetCheck() {
      this.techniquesSelected = [];
      this.$emit('resultTechnics', '');
      

      const arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      sessionStorage.setItem(
        'arregloArticulos',
        JSON.stringify(arregloArticulos.filter((item) => item.name !== 'TECHNICS'))
      );
    },
    getCheckboxValues() {
      const arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      const filterEligender = arrayArticles.filter((item) => item.name !== 'TECHNICS');
      const joinedArticles = [...filterEligender, ...this.techniquesSelected]

      sessionStorage.setItem('arregloArticulos', JSON.stringify(joinedArticles));

    },
    formatPrice(price) {
      return NumberFormat(price);
    },
    propuestaIngenesTechnics() {

      //valida que existe alguna propuesta
      if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
        //valida que propuesta ingenes exista 
        if (this.proposalingen.msg.length) {
          //itera todas las technics

          this.techniques.forEach(item => {
            const technicIngen =  this.proposalingen.msg.find(x => x.cfgd_articulo == item.value && x.cfgd_Categoria == item.data.Categoria)  
            if(technicIngen != null){

              //let exist = this.techniquesSelected.find(x => x.CodigoAlterno == technicIngen.cfgd_articulo && x.Categoria == technicIngen.cfgd_Categoria);
              let exist = this.techniquesSelected.find(x => x.value == technicIngen.cfgd_articulo && x.name == technicIngen.cfgd_Categoria);
              if(exist == null || exist == undefined){
              //                 this.techniquesSelected = [item.data.Articulo, item.data.CodigoAlterno];
              // this.$emit('resultTechnics', this.techniquesSelected);
                this.techniquesSelected.push({
                  name : item.name,
                  value : item.value,
                  amount: '1',
                  price : item.price,
                  description : item.description ,
                  currencyFormat :item.currencyFormat,
                  top: false,
                  data : item.data
                })

                const arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos'));
                const filterEligender = arrayArticles.filter((item) => item.name !== 'TECHNICS');
                const joinedArticlesIngen = [...filterEligender, ...this.techniquesSelected]

                sessionStorage.setItem('arregloArticulos', JSON.stringify(joinedArticlesIngen));

              } 
            }
          }); 
        }
      }
      }
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

.groupSearch {
  width: 50%;
}
</style>
