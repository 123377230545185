var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSurrogate()
    ? _c(
        "b-card",
        {
          staticClass: "text-center",
          attrs: { header: _vm.getHeaders() },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-left pa-0" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-error-ingenes",
                                attrs: {
                                  size: "sm",
                                  disabled: !_vm.selectedBundleId
                                },
                                on: {
                                  click: function($event) {
                                    _vm.selectedBundleId = null
                                  }
                                }
                              },
                              [_vm._v(" Reset ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "text-right pa-0" },
                          [
                            !_vm.creatingBundle
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-ingenes",
                                    attrs: { disabled: !_vm.selectedBundleId },
                                    on: {
                                      click: function($event) {
                                        return _vm.createBundle()
                                      }
                                    }
                                  },
                                  [_vm._v(" Create ")]
                                )
                              : _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-ingenes",
                                    attrs: { disabled: "" }
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "mr-2",
                                      attrs: { small: "" }
                                    }),
                                    _vm._v(" Creating Bundle ")
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex justify-content-start" }),
                    _c("div", { staticClass: "d-flex justify-content-end" })
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3339316857
          )
        },
        [
          _c(
            "b-card-text",
            _vm._l(_vm.bundles, function(bundle) {
              return _c(
                "b-row",
                { key: bundle.id },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { value: bundle.id },
                          model: {
                            value: _vm.selectedBundleId,
                            callback: function($$v) {
                              _vm.selectedBundleId = $$v
                            },
                            expression: "selectedBundleId"
                          }
                        },
                        [_vm._v(" " + _vm._s(bundle.bundleName) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "p-0",
                          attrs: { variant: "link", size: "lg" },
                          on: {
                            click: function($event) {
                              return _vm.openBundleModal(bundle.id)
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "eye-fill", "font-scale": "1" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                "button-size": "md",
                "cancel-variant": "danger",
                "ok-title": "Save",
                "ok-variant": "blue-dark",
                size: "lg",
                title: _vm.selectedBundle[0]
                  ? "Bundle " + _vm.selectedBundle[0].bundleName
                  : "Bundle",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "hide-footer": "",
                scrollable: ""
              },
              model: {
                value: _vm.bundleModal,
                callback: function($$v) {
                  _vm.bundleModal = $$v
                },
                expression: "bundleModal"
              }
            },
            [
              _c("CreateBusinessBundle", {
                attrs: { selectedBundle: _vm.selectedBundle }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm.typeCard === "Multiple"
    ? _c(
        "b-card",
        { staticClass: "text-center", attrs: { header: _vm.getHeaders() } },
        [
          _vm.isLoading || _vm.isLoadingProducts
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Text Centered" }
                  })
                ],
                1
              )
            : _c(
                "b-card-text",
                [
                  _c("div", { staticClass: "search-category" }, [
                    _c(
                      "div",
                      { staticClass: "groupSearch mb-3" },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "inputSearch", attrs: { size: "sm" } },
                          [
                            _c("b-form-input", {
                              staticClass: "custom-input",
                              attrs: {
                                id: "search-category",
                                type: "search",
                                placeholder: this.$i18n.t("welcome.search")
                              },
                              model: {
                                value: _vm.filterOptions,
                                callback: function($$v) {
                                  _vm.filterOptions = $$v
                                },
                                expression: "filterOptions"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "title-items" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("proposal.Article")))]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("proposal.Price")) +
                            " (" +
                            _vm._s(_vm.country === "MX" ? "MXN " : "USD ") +
                            ") "
                        )
                      ])
                    ])
                  ]),
                  _c("b-form-group", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var ariaDescribedby = ref.ariaDescribedby
                          return [
                            _c(
                              "b-form-checkbox-group",
                              {
                                attrs: {
                                  id: "checkbox-group-1",
                                  "aria-describedby": ariaDescribedby,
                                  name: "category",
                                  stacked: ""
                                },
                                model: {
                                  value: _vm.categorySelected,
                                  callback: function($$v) {
                                    _vm.categorySelected = $$v
                                  },
                                  expression: "categorySelected"
                                }
                              },
                              _vm._l(_vm.categories, function(item, index) {
                                return _c(
                                  "b-form-checkbox",
                                  {
                                    key: _vm.nameCategory + "-" + index,
                                    attrs: { value: item.value }
                                  },
                                  [
                                    item.top
                                      ? [
                                          _c("b", { staticClass: "b-ingen" }, [
                                            _vm.proposalingen.msg != null ||
                                            _vm.proposalingen.msg != undefined
                                              ? _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        class: {
                                                          "ingenes-class": _vm.hasIngenesClass(
                                                            item
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                        _c("b", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-secondary"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.formatPrice(
                                                                      item.value
                                                                        .PrecioLista
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]),
                                                        _vm.amountTop - 1 ===
                                                        index
                                                          ? _c("hr")
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.text) +
                                                        " "
                                                    ),
                                                    _c("b", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-secondary"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatPrice(
                                                                  item.value
                                                                    .PrecioLista
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                          ])
                                        ]
                                      : [
                                          _vm.proposalingen.msg != null ||
                                          _vm.proposalingen.msg != undefined
                                            ? _c(
                                                "span",
                                                { staticClass: "no-ingen" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      class: {
                                                        "ingenes-class": _vm.hasIngenesClass(
                                                          item
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.text) +
                                                          " "
                                                      ),
                                                      _c("b", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-secondary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    item.value
                                                                      .PrecioLista
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "no-ingen" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  ),
                                                  _c("b", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-secondary"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                item.value
                                                                  .PrecioLista
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                        ]
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  !_vm.categories.length
                    ? _c("p", { staticClass: "my-2" }, [
                        _vm._v("No result for " + _vm._s(_vm.nameCategory))
                      ])
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    : _c(
        "b-card",
        {
          staticClass: "text-center",
          attrs: { header: _vm.getHeaders() },
          on: { change: _vm.getRadioValues }
        },
        [
          _vm.isLoading || _vm.isLoadingProducts
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Text Centered" }
                  })
                ],
                1
              )
            : _c(
                "b-card-text",
                [
                  _c("div", { staticClass: "search-product" }, [
                    _c(
                      "div",
                      { staticClass: "groupSearch mb-3" },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "inputSearch", attrs: { size: "sm" } },
                          [
                            _c("b-form-input", {
                              staticClass: "custom-input",
                              attrs: {
                                id: "search-" + _vm.nameCategory,
                                type: "search",
                                placeholder: this.$i18n.t("welcome.search")
                              },
                              model: {
                                value: _vm.filterOptions,
                                callback: function($$v) {
                                  _vm.filterOptions = $$v
                                },
                                expression: "filterOptions"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "title-items" }, [
                      _c("span", [_vm._v("Articulo")]),
                      _c("span", [
                        _vm._v(
                          "Precio (" +
                            _vm._s(_vm.country === "MX" ? "MXN " : "USD ") +
                            ")"
                        )
                      ])
                    ])
                  ]),
                  _vm._l(_vm.categories, function(item, index) {
                    return _c("b-form-radio-group", {
                      key: index,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var ariaDescribedby = ref.ariaDescribedby
                              return [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      "aria-describedby": ariaDescribedby,
                                      name: item.category,
                                      value: item
                                    }
                                  },
                                  [
                                    item.top
                                      ? [
                                          _c("b", { staticClass: "b-ingen" }, [
                                            _vm.proposalingen.msg != null ||
                                            _vm.proposalingen.msg != undefined
                                              ? _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        class: {
                                                          "ingenes-class": _vm.hasIngenesClass(
                                                            item
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                        _c("b", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-secondary"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.formatPrice(
                                                                      item.value
                                                                        .PrecioLista
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]),
                                                        _vm.amountTop - 1 ===
                                                        index
                                                          ? _c("hr")
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "no-ingen" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.text) +
                                                        " "
                                                    ),
                                                    _c("b", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-secondary"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatPrice(
                                                                  item.value
                                                                    .PrecioLista
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                          ])
                                        ]
                                      : [
                                          _vm.proposalingen.msg != null ||
                                          _vm.proposalingen.msg != undefined
                                            ? _c(
                                                "span",
                                                { staticClass: "no-ingen" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      class: {
                                                        "ingenes-class": _vm.hasIngenesClass(
                                                          item
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.text) +
                                                          " "
                                                      ),
                                                      _c("b", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-secondary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    item.value
                                                                      .PrecioLista
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "no-ingen" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  ),
                                                  _c("b", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-secondary"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                item.value
                                                                  .PrecioLista
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.categorySelected,
                        callback: function($$v) {
                          _vm.categorySelected = $$v
                        },
                        expression: "categorySelected"
                      }
                    })
                  }),
                  !_vm.categories.length
                    ? _c("p", { staticClass: "my-2" }, [
                        _vm._v("No result for " + _vm._s(_vm.nameCategory))
                      ])
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-danger" },
                      on: { click: _vm.resetCheck }
                    },
                    [_vm._v("Reset")]
                  )
                ],
                2
              )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }