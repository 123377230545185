<template>
    <b-container fluid>
        <b-card
            v-for="phase in selectedBundle[0].phases"
            :key="phase.id"
            border-variant="primary"
            header-bg-variant="primary"
            class="mb-3"
        >
            <template #header>
                <b-row class="d-flex justify-content-between px-3" align-v="center">
                    <h6 class="text-white mb-0">
                        {{ `${phase.phaseName} ${phase.optional ? '- Optional' : ''}` }}
                    </h6>
                    <b-btn
                        v-if="!creatingBundle"
                        size="sm"
                        class="btn-phase"
                        :disabled="hasBranches(phase.id)"
                        @click="createBundleBusinessPhase(phase.id)"
                    >
                        Create Business Phase
                    </b-btn>
                    <b-btn v-else size="sm" class="btn-phase" disabled>
                        <b-spinner small class="mr-2"></b-spinner>
                        Creating Business Phase
                    </b-btn>
                </b-row>
            </template>
            <b-card-text>
                <b-table :items="phase.products" :fields="fields" responsive small bordered>
                    <template #head()="{label}">
                        <p class="text-center align-middle">{{ label }}</p>
                    </template>

                    <template #cell(originBranches)="{item}">
                        <b-form-tag
                            :variant="getTagVariant(item.originBranches, 'origin')"
                            no-remove
                        >
                            {{ getBranch(item.originBranches, 'origin') || 'Not Set' }}
                        </b-form-tag>
                    </template>
                    <template #cell(destinationBranches)="{item}">
                        <b-form-tag
                            :variant="getTagVariant(item.destinationBranches, 'destination')"
                            no-remove
                        >
                            {{ getBranch(item.destinationBranches, 'destination') || 'Not Set' }}
                        </b-form-tag>
                    </template>
                </b-table>
            </b-card-text>
        </b-card>
    </b-container>
</template>

<script>
import { imPostRequest } from '../../../api/imRequests'
import { ICON, AlertForm } from '../../../helpers/Alert'

export default {
    name: 'CreateBusinessBundle',
    props: {
        selectedBundle: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            creatingBundle: false,
            fields: [
                { key: 'originBranches', label: 'Origen', tdClass: 'align-middle' },
                { key: 'destinationBranches', label: 'Destino', tdClass: 'align-middle' },
                { key: 'productName', label: 'Product', tdClass: 'align-middle' },
                { key: 'price', label: 'Price', tdClass: 'align-middle' }
            ],
            bundlePhase: {
                idBundle: null,
                idPhase: null,
                idSedeDestino: JSON.parse(sessionStorage.getItem('sedeDestino')).value,
                idSedeOrigen: JSON.parse(sessionStorage.getItem('sedeOrigen')).value,
                nhc: JSON.parse(sessionStorage.getItem('nhc')),
                nombreSedeDestino: JSON.parse(sessionStorage.getItem('sedeDestino')).name,
                nombreSedeOrigen: JSON.parse(sessionStorage.getItem('sedeOrigen')).name,
                owner_id: JSON.parse(sessionStorage.getItem('ownerId')),
                user_comision: null,
                usuario_creador: this.$store.state.username || localStorage.getItem('userName'),
                vid: JSON.parse(sessionStorage.getItem('vid'))
            }
        }
    },
    methods: {
        hasBranches(phaseId) {
            const bundlePhase = this.selectedBundle[0].phases.find(phase => phase.id === phaseId)

            let canCreate = false

            if (bundlePhase) {
                for (const product of bundlePhase.products) {
                    const isOriginValid = product.originBranches.includes(
                        this.bundlePhase.idSedeOrigen
                    )
                    const isDestinationValid = product.destinationBranches.includes(
                        this.bundlePhase.idSedeDestino
                    )

                    if (!isOriginValid || !isDestinationValid) {
                        canCreate = true
                        break
                    }
                }
            }

            return canCreate
        },
        getTagVariant(branches, type) {
            return this.getBranch(branches, type) ? 'primary' : 'danger'
        },
        getBranch(branches, branch) {
            const sede = JSON.parse(
                sessionStorage.getItem(branch === 'origin' ? 'sedeOrigen' : 'sedeDestino')
            ).value

            const hasBranch = branches.includes(sede)

            return hasBranch ? sede : null
        },
        async createBundleBusinessPhase(phaseId) {
            this.creatingBundle = true
            this.bundlePhase.idBundle = this.selectedBundle[0].id
            this.bundlePhase.idPhase = phaseId

            const { status, data } = await imPostRequest('bundles/bundles', this.bundlePhase)

            if (status === 201) {
                this.creatingBundle = false
                AlertForm('Creation', this.$t('admin.bundles.hsCreationMessage'), ICON.SUCCESS)
                this.$router.push({ name: 'History' })
            } else {
                AlertForm('Creation', data.msg, ICON.ERROR)
            }
            this.creatingBundle = false
        }
    }
}
</script>
