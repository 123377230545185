<template>
  <b-card
    :header="(this.labsConfig[0] && ( country === 'MX'? this.labsConfig[0].label_category: this.$i18n.t('proposal.Laboratory'))) || this.$i18n.t('proposal.Laboratory')"
    class="text-center"
    v-if="typeCard === 'Multiple'"
  >
    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-labs">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-labs"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>
        <hr />
        <div class="title-items">
          <span>{{ $t('proposal.Article') }}</span>
          <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
        </div>
      </div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="laboratorySelected"
          :aria-describedby="ariaDescribedby"
          name="labs"
          stacked
        >
          <b-form-checkbox v-for="(item, index) in laboratories" :value="item.value" :key="'labs-' + index" >
            <template v-if="item.top">
              <b class="b-ingen"><span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
                <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                  {{ item.text }}
                    <b
                    ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b>
                <hr v-if="amountTop - 1 === index" /> 
                    
                </label>
              </span>
              
              <span v-else class="no-ingen">
                {{item.text}} <b
                  ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b> 
              </span></b>              
            </template>
            <template v-else>
              <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
              <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria) }">
                {{item.text}}<b
                  ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
                </b>
              </label>
            </span>
            <span v-else class="no-ingen">
              {{item.text}}<b
                ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
              </b>
            </span>
          </template>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <p v-if="!laboratories.length" class="my-2">No result for Labs</p>
      <b-button size="sm" variant="outline-danger" @click="resetCheck">Reset</b-button>
    </b-card-text>
  </b-card>
  <b-card
    :header="(this.labsConfig[0] && ( country === 'MX'? this.labsConfig[0].label_category: this.$i18n.t('proposal.Laboratory'))) || this.$i18n.t('proposal.Laboratory')"
    class="text-center"
    @change="getCheckboxValues"
    v-else
  >
    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-product">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-labs"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>

        <hr />
        <div class="title-items">
          <span>{{ $t('proposal.Article') }}</span>
          <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
        </div>
      </div>
      <b-form-radio-group
        v-model="laboratorySelected"
        v-slot="{ ariaDescribedby }"
        v-for="(item, index) in laboratories"
        :key="index"
      >
        <b-form-radio :aria-describedby="ariaDescribedby" :name="item.category" :value="item">
          <template v-if="item.top">
            <b
              >{{ item.text }}
              <hr v-if="amountTop - 1 === index" /> </b
            ><b
              ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
            </b></template
          >
          <template v-else
            >{{ item.text
            }}<b
              ><span class="text-secondary">{{ formatPrice(item.value.PrecioLista) }}</span>
            </b></template
          >
        </b-form-radio>
      </b-form-radio-group>
      <p v-if="!laboratories.length" class="my-2">No result for Labs</p>
      <b-button size="sm" variant="outline-danger" @click="resetCheck">Reset</b-button>
    </b-card-text>
  </b-card>
</template>

<script>
import { API_PRODUCTO_CATEGORIA } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';
import { NumberFormat } from '../../helpers/NumberFormat';

export default {
  name: 'LaboratoriesSection',
  props: ['dataProducts', 'isLoadingProducts', 'topProducts', 'labsConfig', 'proposalingen'],
  data() {
    return {
      laboratorySelected: [],
      laboratories: [],
      allLaboratories: [],
      country: '',
      listArticles: [],
      filterOptions: '',
      isLoading: false,
      amountTop: null,
      typeCard: 'Multiple',
    };
  },

  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.listArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || [];
    await this.updateLaboratories();
    await this.propuestaIngenesLaboratorio();
  },
  watch: {
    laboratorySelected() {
      let arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || [];
      arrayArticles = arrayArticles.filter((el) => !['LABS', 'LAB.GRAL'].includes(el.name));
      if (this.laboratorySelected.length) {
        if (this.typeCard === 'Multiple') {
          this.laboratorySelected.forEach((el) => {
            arrayArticles.push({
              name: el.Categoria,
              value: el.Articulo,
              amount: '1',
              description: el.CodigoAlterno,
              price: el.PrecioLista.toString(),
              currencyFormat: el.monedaPrecio,
              data: el,
            });
          });
        }
      }
      sessionStorage.setItem('arregloArticulos', JSON.stringify(arrayArticles));
    },

    async dataProducts() {
      await this.updateLaboratories();
    },
    async topProducts() {
      await this.updateLaboratories();
    },
    async labsConfig() {
      setTimeout(() => {
        this.updateLaboratories();
      }, 2000);
    },
    filterOptions() {
      this.laboratories = this.allLaboratories.filter((el) =>
        el.text.toLowerCase().includes(this.filterOptions.toLowerCase())
      );
      this.amountTop = this.laboratories.filter((el) => el.top === true).length;
    },
  },
  methods: {
    async updateLaboratories() {
      try {
        let dataLabs = [];
        this.isLoading = true;
        if (this.labsConfig.length) {
          dataLabs = this.labsConfig;
          this.typeCard = this.labsConfig[0].tipocard;
        } else {
          if (this.topProducts !== null) {
            dataLabs = this.topProducts;
          } else if (this.country === 'US') {
            let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}LABS`);
            dataLabs = data.productos;
          } else {
            dataLabs = this.dataProducts;
          }
        }

        let cantidad = 0;
        this.laboratories = dataLabs.map((el) => {
          if (el.isTop) cantidad++;
          return {
            text: el.CodigoAlterno,
            value: el,
            top: el.isTop ? true : false,
          };
        });
        this.amountTop = cantidad;
        this.allLaboratories = this.laboratories;
        let filterLaboratory = this.listArticles.filter((el) => ['LABS', 'LAB.GRAL'].includes(el.name));

        filterLaboratory = filterLaboratory.map((el) => {
          return el.description;
        });

        filterLaboratory = this.laboratories.filter((el) => filterLaboratory.includes(el.text));

        this.laboratorySelected = filterLaboratory.map((el) => el.value);
        if (this.typeCard === 'Unico' && this.laboratorySelected.length) {
          this.laboratorySelected = {
            text: this.laboratorySelected[0].CodigoAlterno,
            top: this.laboratorySelected[0].isTop,
            value: this.laboratorySelected[0],
          };
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status || ''}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoading = false;
      }
    },
    getRadioValues() {
      const arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      const filterLabs = arrayArticles.filter((el) => !['LABS', 'LAB.GRAL'].includes(el.name));
      let dataSelected = {
        amount: '1',
        currencyFormat: this.laboratorySelected.value.monedaPrecio,
        description: this.laboratorySelected.text,
        name: this.laboratorySelected.value.Categoria,
        price: this.laboratorySelected.value.PrecioLista,
        top: this.laboratorySelected.top,
        value: this.laboratorySelected.value.Articulo,
        data: this.laboratorySelected.value,
      };

      filterLabs.push(dataSelected);

      sessionStorage.setItem('arregloArticulos', JSON.stringify(filterLabs));
    },
    //  resetCheck() {
    //   this.surgerySelected = '';

    //   const arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
    //   sessionStorage.setItem(
    //     'arregloArticulos',
    //     JSON.stringify(arregloArticulos.filter((el) => !['LABS', 'LAB.GRAL'].includes(el.name)))
    //   ); 
    // },
    resetCheck() {
      this.laboratorySelected = [];

      const arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      sessionStorage.setItem(
        'arregloArticulos',
        JSON.stringify(arregloArticulos.filter((el) => el.name !== 'LABS'))
      );
    },
    getCheckboxValues() {
      const arrayArticles = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      const filterEligender = arrayArticles.filter((el) => el.name !== 'LABS');
      const joinedArticles = [...filterEligender, ...this.laboratorySelected]
      sessionStorage.setItem('arregloArticulos', JSON.stringify(joinedArticles));
    },

    formatPrice(price) {
      return NumberFormat(price);
    },
    propuestaIngenesLaboratorio(){
      if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
        if (this.proposalingen.msg.length) {
          this.laboratories.forEach(item => {
            let laboratoryIngen = this.proposalingen.msg.find(x => x.cfgd_articulo == item.text && x.cfgd_Categoria == item.value.Categoria)  
            if(laboratoryIngen != null){
              let exist = this.laboratorySelected.find(x => x.CodigoAlterno == laboratoryIngen.cfgd_articulo && x.Categoria == laboratoryIngen.cfgd_Categoria);
              if(exist == null || exist == undefined){
                this.laboratorySelected.push(item.value)
              }    
            }
          }); 
        }
      }
    }
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';
.groupSearch {
  width: 50%;
}
</style>
