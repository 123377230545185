<template>
  <b-container class="proposal__page">
    <Owner />
    <NavbarInfo />
    <b-row class="text-center" align-v="center">
      <h3 class="title mr-5">{{ $t("proposal.Title") }}</h3>
      <NoProposal />
    </b-row>

    <b-row class="text-center" align-v="start" v-if="!isLoadingCards ">
      <b-col
        cols="12"
        md="6"
        v-if="
          configRol.sspta.pestana.programs &&
            ((country === 'MX' && becConfig.length) || country === 'US' && 
            becConfig.length > 0)
        "
      >
        <b-card-group deck>
          <Programs
            :coverageSelected="coverageSelected"
            @resultCoverage="dataCoverage"
            @resultPrograms="dataPrograms"
            :dataProducts="productsMxBec"
            :dataAllProducts="productsMxAllBec"
            :isLoadingProducts="isLoading"
            :becConfig="becConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>
      <b-col cols="12" md="6" v-if="configRol.sspta.pestana.technics && techniquesConfig.length">
        <b-card-group deck>
          <Techniques
            :dataProducts="productsMxTechniques"
            :isLoadingProducts="isLoading"
            :topProducts="productTop.TECHNICS || null"
            :techniquesConfig="techniquesConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>
      <b-col cols="12" md="6" v-if="programasSelect">
        <b-card-group deck>
          <Coverage
            :programasSelected="programasSelected"
            @resultCoverage="dataCoverage"
            :coverageConfig="coverageConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>
      <!-- <b-col cols="12" md="6" v-if="configRol.sspta.pestana.labs && labsConfig.length"> -->
      <b-col
        cols="12"
        md="6"
        v-if="
          configRol.sspta.pestana.labs &&
            ((country === 'MX' && labsConfig.length) || country === 'US' && includesLabs)
        "
      >
        <!-- <b-col cols="12" md="6" v-if="configRol.sspta.pestana.labs">  -->
        <b-card-group deck>
          <Laboratory
            :dataProducts="productsMxLabs"
            :isLoadingProducts="isLoading"
            :topProducts="productTop.LABS || productTop['LAB.GRAL'] || null"
            :labsConfig="labsConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>
      <!-- <b-col cols="12" md="6" v-if="configRol.sspta.pestana.surgeries && surgeriesConfig.length"> -->
      <b-col
        cols="12"
        md="6"
        v-if="
          configRol.sspta.pestana.surgeries &&
            ((country === 'MX' && surgeriesConfig.length) || country === 'US' && includesSurgeries)
        "
      >
        <b-card-group deck>
          <Surgery
            :dataProducts="productsMxSurgery"
            :isLoadingProducts="isLoading"
            :topProducts="productTop.SURGERIES || productTop.CIRUGIA || null"
            :surgeriesConfig="surgeriesConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>

      <!-- treatment -->
      <b-col cols="12" md="6" v-if="country !== 'MX' && includesTreatment">
        <b-card-group deck>
          <Treatment
            :dataProducts="productsUsTreatment"
            :isLoadingProducts="isLoading"
            :topProducts="productTop.TREATMENT || null"
            :treatmentConfig="treatmentConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>

      <!-- FIRST APPOINTMENT -->
      <b-col cols="12" md="6" v-if="country !== 'MX' && includesFirstAppointment">
        <b-card-group deck>
          <FirstAppointment
            :dataProducts="productsUsFirstAppointment"
            :isLoadingProducts="isLoading"
            :topProducts="productTop['FIRST APPOINTMENT'] || null"
            :FirstAppointmentConfig="FirstAppointmentConfig"
            :proposalingen="proposalingen"
          />
        </b-card-group>
      </b-col>
      <!-- MAC -->
      <!-- Revisar como seria la configuración en roles -->

      <template v-for="(category, idx) in othersCategory">
        <b-col
          cols="12"
          md="6"
          :key="`category ${idx}`"
          v-if="configRol.sspta.pestana[category[0].Categoria] || superAdmin"
        >
          <b-card-group deck>
            <CardCategory
              :isLoadingProducts="isLoading"
              :categoryConfig="category"
              :nameCategory="category[0].Categoria"
              :proposalingen="proposalingen"
            />
          </b-card-group>
        </b-col>
      </template>
    </b-row>
    <b-row class="justify-content-center my-4" v-else>
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </b-row>
    <b-row
      align-v="start"
      v-if="configRol.sspta.pestana.medicinesIndividual || configRol.sspta.pestana.medicinesPackage"
    >
      <b-col cols="12" md="6" class="p-0">
        <SelectPharmacy />
      </b-col>
    </b-row>
    <b-row align-v="start" v-if="configRol.sspta.pestana.medicinesPackage">
      <b-col class="p-0">
        <FilterMedicine />
      </b-col>
    </b-row>
    <b-row class="text-center" align-v="start" v-if="configRol.sspta.pestana.medicinesPackage">
      <b-col>
        <PackageMedicine />
      </b-col>
    </b-row>
    <b-row class="text-center" align-v="start" v-if="configRol.sspta.pestana.medicinesIndividual">
      <b-col>
        <IndividualMedicine />
      </b-col>
    </b-row>

    <b-row class="bottomRow">
      <b-navbar fixed="bottom" class="fixedNavbar mb-3">
        <router-link class="btn btn-blue-dark" to="/recipe/sspta">
          <span>{{ $t("proposal.Go") }}</span>
          <b-icon icon="arrow-right" class="icon" aria-hidden="true"></b-icon>
        </router-link>
      </b-navbar>
    </b-row>
  </b-container>
</template>

<script>
import Owner from "@/components/Owner";
import NoProposal from "@/components/Proposal/NoProposal";
import Techniques from "@/components/Proposal/Techniques";
import Coverage from "@/components/Proposal/Coverage";
import Programs from "@/components/Proposal/Programs";
import PackageMedicine from "@/components/Proposal/PackageMedicine";
import SelectPharmacy from "@/components/Proposal/SelectPharmacy";
import FilterMedicine from "@/components/Proposal/FilterMedicine";
import Laboratory from "@/components/Proposal/Laboratory";
import NavbarInfo from "@/components/NavbarInfo";
import IndividualMedicine from "@/components/Proposal/IndividualMedicine";
import Surgery from "@/components/Proposal/Surgery";
import Treatment from "@/components/Proposal/Treatment";
import FirstAppointment from "@/components/Proposal/FirstAppointment";
//import Mac from "@/components/Proposal/MacRequest";
//import Mac from "@/components/Proposal/MacRequest";

import CardCategory from "@/components/Proposal/CardCategory";
import { API_HUBSPOT, API_ROOT } from "../Constants/Api";
import { AlertForm, ICON } from "../helpers/Alert";
import { ApiRequest } from "../helpers/ApiRequest";

export default {
  name: "Proposal",
  components: {
    NavbarInfo,
    Owner,
    NoProposal,
    Techniques,
    Coverage,
    Programs,
    PackageMedicine,
    SelectPharmacy,
    FilterMedicine,
    Laboratory,
    IndividualMedicine,
    Surgery,
    Treatment,
    CardCategory,
    FirstAppointment,
  },
  data() {
    Techniques;
    return {
      coverageSelected: "",
      programasSelected: "",
      protectionSelected: "",
      programsStarAtHome: ["YSAH 3", "YSAH 4"],
      programsProtection: ["BEC 3 PROT50"],
      country: "",
      allProducts: [],
      productsMxBec: [],
      productsMxAllBec: [],
      productsMxSurgery: [],
      productsMxLabs: [],
      productsMxTechniques: [],
      productsUsTreatment: [],
      productsUsFirstAppointment: [],
      productsMac: [],
      productsMxEligender: [],
      isLoading: false,
      // Top10
      productTop: [],
      configRol: {},
      configBussiness: [],
      // conf Programs
      configPrograms: [],
      //dataconfig
      becConfig: [],
      techniquesConfig: [],
      coverageConfig: [],
      proteccionConfig: [],
      labsConfig: [],
      surgeriesConfig: [],
      treatmentConfig: [],
      FirstAppointmentConfig: [],
      MacConfig: [],
      isLoadingCards: true,

      optionsCategory: [],
      categoryConfig: [],
      others: null,
      othersCategory: [],
      superAdmin: false,
      proposalingen: [],

      //configcards - USA
      includesLabs: false,
      includesSurgeries: false,
      includesTreatment: false,
      includesFirstAppointment: false,
      includesMac: false,
    };
  },
  created() {
    this.configRol = this.$store.state.configRol;
    this.configBussiness = this.$store.state.allConfigBussiness;
    this.productsMxAllBec = this.configBussiness.filter((el) => el.Categoria === "BEC");
    this.configPrograms = this.$store.state.configPrograms;
    if (this.configRol.sspta.ventana === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(
        `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.Proposal")}`,
        this.$i18n.t("permissions.validateAdmin"),
        ICON.WARNING,
        "center",
        null
      );
    }
    //this.proposalingen = this.getConfigProposalIngenes();
  },
  async mounted() {
    this.country = sessionStorage.getItem("country");
    this.superAdmin = this.$store.state.rol.toLowerCase().includes("super admin");
    let dataPrograms = JSON.parse(sessionStorage.getItem("arregloArticulos") || "[]");
    sessionStorage.setItem(
      "arregloArticulos",
      JSON.stringify(dataPrograms.filter((el) => !el.name.includes("MEDICINA")))
    );

    await this.initialAppointmentBranch();

    await this.getConfigBranch()
    //await this.getConfigProposalIngenes();
    if (this.configBussiness) {
      this.listProducts();
    } else {
      this.isLoadingCards = false;
    }
  },
  computed: {
    programasSelect() {
      let validation = this.programsStarAtHome.includes(this.programasSelected[0]);
      return validation;
    },
    programasSelectProtection() {
      let validation = this.programsProtection.includes(this.programasSelected[0]);
      return validation;
    },
    initialAppointmentBranchSelected() {
      return this.$store.state.sede_origen;
    },
    updateConfigRol() {
      return this.$store.state.configRol;
    },
    updateConfigBussiness() {
      return this.$store.state.allConfigBussiness;
    },
  },
  watch: {
    updateConfigRol() {
      this.configRol = this.updateConfigRol;
      if (this.configRol.sspta?.ventana === false) {
        this.$router.push({ name: "Welcome" });
        AlertForm(
          `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.Proposal")}`,
          this.$i18n.t("permissions.validateAdmin"),
          ICON.WARNING,
          "center",
          null
        );
      }
    },
    async updateConfigBussiness() {
      if (this.updateConfigBussiness) {
        this.configBussiness = this.updateConfigBussiness;
        this.isLoadingCards = true;
        await this.initialAppointmentBranch();
        await this.listProducts();

        this.isLoadingCards = false;
      }
    },
    programasSelectProtection() {
      if (!this.programasSelectProtection) this.deleteItem("PROTECCION");
    },
  },
  methods: {
     async getConfigBranch() {
      const sucursalOrigen = JSON.parse(sessionStorage.getItem('sedeOrigen'));
      const subtipo = sessionStorage.getItem('subtipo');

      try {
        let data = await ApiRequest.post(`${API_ROOT}pipelines/configXsucursal`, {
          sucursal: sucursalOrigen.name,
          subtipo
        });
    
        const categoriasXsubtipo = data.categoriasXsubtipo;

        for (const categoria of categoriasXsubtipo) {
          if (categoria.toLowerCase().includes('lab'))    this.includesLabs = true;
          if (categoria.toLowerCase().includes('surger'))    this.includesSurgeries = true;
          if (categoria.toLowerCase().includes('treatment')) this.includesTreatment = true;
          if (categoria.toLowerCase().includes('first'))     this.includesFirstAppointment = true;
          if (categoria.toLowerCase().includes('more'))      this.includesMac = true;
        }

        this.configBussiness = data.productos

    } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText || this.$i18n.t('permissions.errorBranch'), ICON.ERROR);
      }
    },
    async initialAppointmentBranch() {
      try {
        const data = await ApiRequest.post(`${API_ROOT}cards/obtenerCardPais`, {
          localidad: this.country === "US" ? 2 : 1,
          sede_origen: this.initialAppointmentBranchSelected.name,
          rol: this.$store.state.idRol,
        });
        this.productTop = data.categorias_productos ? data.categorias_productos : [];
      } catch (err) {
        if (err.status === "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR, "center");
        } else {
          AlertForm(
            `Error Code: ${err.status}`,
            err.data.msg
              ? `${err.data.msg}: ${err.data.apiExternaMsg}`
              : this.$i18n.t("permissions.errorCard"),
            ICON.ERROR,
            "center",
            null
          );
        }
      }
    },
    dataCoverage(data) {
      this.coverageSelected = data;
    },

    dataPrograms(data) {
      this.programasSelected = data;
    },
    async listProducts() {
      // LISTA DE CONFIGURACIONES POR CORREO Y SUCURSAL

      //TODOS LOS PRODUCTOS USA O MX
      try {
        let ApiCountry = this.country === "US" ? "productos" : "productos/productosMX";
        let data = await ApiRequest.get(`${API_ROOT}${ApiCountry}`);
        if (this.country === "MX") {
          let findProductProt = data.data.find((el) => el.Articulo === "BEC PROT 50");
          sessionStorage.setItem("PROT 50", JSON.stringify(findProductProt));
        }
        this.allProducts = data.productos || data.data;

        //************************************* */
        const dataHB = await ApiRequest.post(`${API_HUBSPOT}getInternational`, {
          email: sessionStorage.getItem("email"),
        });
        let dataHubspot = dataHB.body.properties;
        let negocio =
          dataHubspot.unidad_de_negocio != undefined ? dataHubspot.unidad_de_negocio.value : "";
        let score =
          dataHubspot.score_azul_verde != undefined ? dataHubspot.score_azul_verde.value : "";
        let socioeconomico = dataHubspot.score_socioeconomico_mx
          ? dataHubspot.score_socioeconomico_mx.value
          : "";
        let scoreAlto =
          dataHubspot.score_alto___bajo != undefined ? dataHubspot.score_alto___bajo.value : "";
        let buyer =
          dataHubspot.buyer_persona_mexico != undefined
            ? dataHubspot.buyer_persona_mexico.value
            : "";

        if (dataHubspot.estado_de_pvr != undefined) {
          if (dataHubspot.estado_seguimiento_comercial == undefined) {
            if (dataHubspot.estado_de_pvr.value == "Asistió") {
              const data2 = await ApiRequest.publicPost(
                `${API_ROOT}preconfiguracion/getPreConfigurationDetail`,
                {
                  country: this.country,
                  unidad_de_negocio: negocio,
                  score_azul_verde: score,
                  score_socioeconomico_mx: socioeconomico,
                  score_alto__bajo: scoreAlto,
                  buyer_persona_mexico: buyer,
                }
              );
              if (data2.msg != "No existe la configuracion correspondiente") {
                this.proposalingen = data2 ? data2 : [];
              } else {
                this.proposalingen = [];
                AlertForm("Propuesta Ingenes", "Perfil sin propuesta configurada.", ICON.INFO);
              }

              //Logica

              const dataConjunto = await ApiRequest.publicPost(
                `${API_ROOT}preconfiguracion/getPreConfigurationDetail`,
                {
                  country: this.country,
                  unidad_de_negocio: negocio,
                  score_azul_verde: score,
                  score_socioeconomico_mx: socioeconomico,
                  score_alto__bajo: scoreAlto,
                  buyer_persona_mexico: buyer,
                }
              );
              if (dataConjunto.msg != "No existe la configuracion correspondiente") {
                this.proposalingen = dataConjunto ? dataConjunto : [];
              } else {
                this.proposalingen = [];
                AlertForm("Propuesta Ingenes", "Perfil sin propuesta configurada.", ICON.INFO);
              }
            }
          } else {
            if (dataHubspot.estado_seguimiento_comercial.value == "") {
              const data3 = await ApiRequest.publicPost(
                `${API_ROOT}preconfiguracion/getPreConfigurationDetail`,
                {
                  country: this.country,
                  unidad_de_negocio: negocio,
                  score_azul_verde: score,
                  score_socioeconomico_mx: socioeconomico,
                  score_alto__bajo: scoreAlto,
                  buyer_persona_mexico: buyer,
                }
              );
              if (data3.msg != "No existe la configuracion correspondiente") {
                //Si existe configuracion
                this.proposalingen = data3 ? data3 : [];
              } else {
                this.proposalingen = [];
                AlertForm("Propuesta Ingenes", "Perfil sin propuesta configurada.", ICON.INFO);
              }
            }
          }
        }
      } catch (err) {
        AlertForm(
          "Error Code:",
          err.data.statusText || this.$i18n.t("permissions.errorProducts"),
          ICON.ERROR,
          "center",
          null
        );
      }

      //BEC SECTION
      let findBec = this.productTop.BEC || this.productTop["BEC INTERNACIONAL"] || [];

      let becTop = findBec.filter((el) => el.isTop === true);

      let becBussiness = this.configBussiness.filter((el) =>
        ["BEC", "BEC INTERNACIONAL"].includes(el.Categoria)
      );
      // validar configuraciones de programas
      let articleBecConfig = this.configPrograms.map((el) => el.Articulo);
      let filterPrograms = becBussiness.filter((el) => articleBecConfig.includes(el.Articulo));
      this.becConfig = this.validatePrograms(becTop, filterPrograms);
      //TECHNICS SECTION
      let findTechniques = this.productTop.TECHNICS || this.productTop.TECNICAS || [];
      let tecniquesTop = findTechniques.filter((el) => el.isTop === true);
      let techniquesBussiness = this.configBussiness.filter((el) =>
        ["TECHNICS", "TECNICAS"].includes(el.Categoria)
      );
      this.techniquesConfig = this.validatePrograms(tecniquesTop, techniquesBussiness);

      //TREATMENT SECTION
      let findTreatment = this.productTop.TREATMENT || [];
      let treatmentTop = findTreatment.filter((el) => el.isTop === true);
      let treatmentBussiness = this.configBussiness.filter((el) =>
        ["TREATMENT"].includes(el.Categoria)
      );

      this.treatmentConfig = this.validatePrograms(treatmentTop, treatmentBussiness);

      //FIRST APPOINTMENT SECTION
      let findFirstAppointment = this.productTop["FIRST APPOINTMENT"] || [];
      let FirstAppointmentTop = findFirstAppointment.filter((el) => el.isTop === true);
      let FirstAppointmentBussiness = this.configBussiness.filter((el) =>
        ["FIRST APPOINTMENT"].includes(el.Categoria)
      );
      this.FirstAppointmentConfig = this.validatePrograms(
        FirstAppointmentTop,
        FirstAppointmentBussiness
      );

      //MAC
      let findMac = this.productTop["MORE"] || [];
      let MacTop = findMac.filter((el) => el.isTop === true);
      let MacBussiness = this.configBussiness.filter((el) =>
        ["MORE"].includes(el.Categoria)
      );
      this.MacConfig = this.validatePrograms(
        MacTop,
        MacBussiness
      );


      // ASOCIACIONES CARDS
      this.coverageConfig = this.allProducts.filter((el) => ["COVERAGE"].includes(el.Categoria));
      this.proteccionConfig = this.allProducts.filter((el) =>
        ["PROTECCION"].includes(el.Categoria)
      );

      //LABS SECTION
      let findLabs = this.productTop.LABS || this.productTop["LAB.GRAL"] || ["LABS"];
      let labsTop = findLabs.filter((el) => el.isTop === true);

      let labsBussiness = this.configBussiness.filter((el) =>
        ["LABS", "LAB.GRAL"].includes(el.Categoria)
      );
      this.labsConfig = this.validatePrograms(labsTop, labsBussiness);

      //SURGERIES SECTION
      let findSurgeries = this.productTop.SURGERIES || this.productTop.CIRUGIA || [];
      let surgeriesTop = findSurgeries.filter((el) => el.isTop === true);
      let surgeriesBussness = this.configBussiness.filter((el) =>
        ["SURGERIES", "CIRUGIA"].includes(el.Categoria)
      );
      this.surgeriesConfig = this.validatePrograms(surgeriesTop, surgeriesBussness);
      this.isLoadingCards = false;
      //Others SECTION
      await this.getProductsXCountry();

      this.othersCategory = [];
      this.topOtherCategory = {};
      for (const category of this.optionsCategory) {
        let find = this.productTop[category] || [];
        let filterTop = find.filter((el) => el.isTop === true);
        let filterCategory = this.configBussiness.filter((el) => el.Categoria === category);
        let topCategory = this.validatePrograms(filterTop, filterCategory);
        if (filterCategory.length) {
          this.othersCategory.push(topCategory);
        }
      }
    },

    async getProductsXCountry() {
      try {
        const data = await ApiRequest.post(`${API_ROOT}cards/productosCategoriaPais`, {
          localidad: this.country === "US" ? 2 : 1,
        });
        this.optionsCategory = Object.keys(data.categorias_productos);
        let unnecesaryCategory = [
          "BEC",
          "BEC INTERNACIONAL",
          "TECHNICS",
          "TECNICAS",
          "COVERAGE",
          "LABS",
          "LAB.GRAL",
          "SURGERIES",
          "CIRUGIA",
          "FUNGIBLES - MEDICAMENTOS",
          "PAQUETE MEDICAMENTO",
          "TREATMENT",
          "FIRST APPOINTMENT",
        ];
        this.optionsCategory = this.optionsCategory.filter(
          (el) => !unnecesaryCategory.includes(el)
        );
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorProducts"), ICON.ERROR);
      }
    },
    validatePrograms(top, bussiness) {
      if (top.length) {
        let filterData = [];
        for (const item of top) {
          let findObj = bussiness.find((el) => el.Articulo === item.Articulo);
          if (!findObj) continue;
          findObj.isTop = true;
          filterData.push(findObj);
          bussiness = bussiness.filter((el) => el.Articulo !== item.Articulo);
        }
        return [...filterData, ...bussiness];
      }
      return bussiness;
    },
    deleteItem(category) {
      let programsAssociation = [...this.programsStarAtHome, ...this.programsProtection];
      const arregloArticulos = JSON.parse(sessionStorage.getItem("arregloArticulos"));
      sessionStorage.setItem(
        "arregloArticulos",
        JSON.stringify(arregloArticulos.filter((el) => el.name !== category))
      );
      console.log(programsAssociation);
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";

/** TITLE MAIN */

.title {
  text-align: left;
  margin: 30px 0px;
}

/** styles buttons */
.btn-blue-dark .icon {
  align-self: center;
  margin-left: 10px;
}

.fixedNavbar {
  padding-left: 40%;
  margin-left: 30%;
  width: 70%;
}

.bottomRow {
  margin-top: 10%;
}
</style>
