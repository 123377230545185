<template>
  <b-card-group deck>
    <b-card :header="this.$i18n.t('proposal.Medicine.titleIndividual')" class="text-center tableMedicine">
      <b-card-text class="mt-3 ml-4 tableMedicineItem">
        <!-- User Interface controls -->
        <b-row>
          <b-col></b-col>
          <b-col class="mb-3">
            <b-input-group size="md" class="inputSearch">
              <b-form-input
                id="filter-input-2"
                v-model="filterIndividual"
                type="search"
                class="custom-input"
                :placeholder="this.$i18n.t('proposal.Medicine.searchIndividual')"
              >
              </b-form-input>
            </b-input-group>
            <b-icon icon="search" aria-hidden="true" flip-h class="input__search__icon"></b-icon>
          </b-col>
        </b-row>

        <!-- TABLE INDIVIDUAL MEDICINE-->
        <b-table
          :items="itemsIndividual"
          :fields="fieldsIndividual"
          :filter="filterIndividual"
          :filter-included-fields="filterIndividualOn"
          stacked="md"
          show-empty
          small
          borderless
          hover
          table-variant="light"
          class="tableFirst"
          sort-icon-left
          select-mode="multi"
          selectable
          selected-variant=""
          @row-selected="onRowSelectedIndividual"
          ref="selectableTable"
          :busy="isLoading"
          :empty-text="this.$i18n.t('proposal.noFound')"
        >
          <template #cell(CodigoAlterno)="row">
            <b>{{ row.value }}</b>
          </template>
          <template #cell(PrecioLista)="row">
            <b>{{ formatNumber(row.value) }}</b>
          </template>
          <template #cell(Disponible)="row">
            <b v-if="row.value">{{ +(Math.round(row.value + 'e+2') + 'e-2') }}</b>
            <b v-else class="text-danger">{{ row.value || 0 }}</b>
          </template>

          <template #cell(actions)="{ rowSelected, item }">
            <template v-if="rowSelected">
              <div class="inputAmount" v-if="country === 'MX'">
                <div class="number-input">
                  <input
                    size="sm"
                    v-model="numbers[item.prod_id]"
                    @keyup="comparacionValor(item.Disponible || 0, item.prod_id)"
                    class="quantity mr-3"
                    min="0"
                    name="quantity"
                    type="number"
                    :max="item.Disponible"
                    :disabled="!item.Disponible"
                  />
                </div>
                <span aria-hidden="true" v-if="item.Disponible">&check;</span>
                <small aria-hidden="true" v-else class="text-danger">Sin Stock</small>
                <span class="sr-only">Selected</span>
              </div>
              <div v-else>
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </div>
            </template>
            <template v-else>
              <div class="inputAmount" v-if="country === 'MX'">
                <div class="number-input">
                  <input
                    size="sm"
                    v-model="numbers[item.prod_id]"
                    class="quantity"
                    min="0"
                    name="quantity"
                    value="1"
                    type="number"
                    :max="item.Disponibilidad"
                    disabled
                  />
                </div>
              </div>
            </template>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-card>
  </b-card-group>
</template>

<script>
import { API_ROOT } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import { NumberFormat } from '../../helpers/NumberFormat';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: 'IndividualMedicine',
  data() {
    return {
      filterIndividual: null,
      filterIndividualOn: [],
      itemsIndividual: [],
      numbers: {},
      selectedIndividual: [],
      saveSelected: [],
      infoRow: [],
      country: '',
      isLoading: false,
      arrayTop: [],
    };
  },
  watch: {
    individualMedicine() {
      let dataPrograms = JSON.parse(sessionStorage.getItem('arregloArticulos')).filter(
        (el) => el.name !== 'MEDICINA INDIVIDUAL'
      );

      this.individualMedicine.forEach((el) => {
        dataPrograms.push({
          name: 'MEDICINA INDIVIDUAL',
          price: el.PrecioLista,
          amount: el.cantidad,
          value: el.prod_id,
          description: el.CodigoAlterno,
          currencyFormat: el.monedaPrecio,
          data: el,
        });
      });
      sessionStorage.setItem('arregloArticulos', JSON.stringify(dataPrograms));
    },
    async pharmacySelected() {
      if (!this.pharmacySelected.value) return;
      try {
        const data = await ApiRequest.post(`${API_ROOT}cards/listarCardMI`, {
          localidad: this.country === 'US' ? 2 : 1,
          sede_origen: this.$store.state.sede_origen.name,
          nombre_farmacia: 'N/A',
          rol: this.$store.state.idRol,
        });
        this.arrayTop = data.top_diez;
      } catch (err) {
        if (err.statusText !== 'No hay un top 10 a re ordenar') {
          return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      }
      try {
        this.isLoading = true;
        const data = await ApiRequest.post(
          `${API_ROOT}filtrosMedicamentos/medicamentosIndividuales`,
          this.pharmacySelected.value
        );
        //ordenar informacion
        let topArray = [];
        this.arrayTop.forEach((item) => {
          let foundData = data.data.find((el) => el.Articulo === item);
          if (foundData) topArray.push(foundData);
        });

        let otherData = data.data.filter((el) => !this.arrayTop.includes(el.Articulo));

        this.itemsIndividual = [...topArray, ...otherData];
      } catch (err) {
        if (err.status === 401) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('proposal.errorIndividual'), ICON.WARNING);
        } else {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.pharmacy.messageError'), ICON.WARNING);
        }
        this.itemsIndividual = [];
      } finally {
        this.isLoading = false;
      }
    },
    initialAppointmentBranchSelected() {
      this.itemsIndividual = [];
    },
  },
  computed: {
    individualMedicine() {
      if (!this.selectedIndividual) return;
      if (Object.keys(this.selectedIndividual).length > 0) {
        let data = [];
        for (const key in this.selectedIndividual) {
          if (Number(this.selectedIndividual[key]) > 0)
            data.push({
              id: Number(key),
              cantidad: Number(this.selectedIndividual[key]),
            });
        }

        let idMedicine = data.map((el) => el.id);
        let cantidadMedicina = this.itemsIndividual.map((medicine) => {
          let cantidad = data.find((el) => el.id === medicine.prod_id) || {
            cantidad: 0,
          };
          medicine.cantidad = cantidad.cantidad;
          return medicine;
        });

        let filterData = cantidadMedicina.filter((el) => idMedicine.includes(el.prod_id));

        return filterData;
      }
      return [];
    },
    fieldsIndividual() {
      return [
        {
          key: 'CodigoAlterno',
          label: this.$i18n.t('proposal.Medicine.Medicine'),
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'PrecioLista',
          label: `${this.$i18n.t('proposal.Medicine.Price')} (MXN)`,
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'Disponible',
          label: 'Stock',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'actions', label: this.$i18n.t('proposal.Medicine.Amount') },
      ];
    },
    pharmacySelected() {
      return this.$store.state.pharmacy_selected;
    },
    initialAppointmentBranchSelected() {
      return this.$store.state.sede_origen;
    },
  },

  mounted() {
    this.country = sessionStorage.getItem('country');
  },

  methods: {
    formatNumber(number) {
      return NumberFormat(number);
    },
    onRowSelectedIndividual(items) {
      //if (this.filterIndividual) return;
      this.selectedIndividual = null;
      this.itemsIndividual.forEach((el) => {
        if (items.find((item) => item.CodigoAlterno === el.CodigoAlterno)) {
          el['_rowVariant'] = el.Disponible ? 'success' : 'danger';
        } else {
          el['_rowVariant'] = '';
        }
      });
      this.saveSelected = items;

      if (this.country === 'US') {
        let programs = JSON.parse(sessionStorage.getItem('arregloArticulos')).filter(
          (el) => el.name !== 'MEDICINA INDIVIDUAL'
        );

        items.forEach((el) => {
          if (el.Disponible > 0) {
            programs.push({
              name: 'MEDICINA INDIVIDUAL',
              value: el.Articulo,
              amount: '0',
              price: el.PrecioLista,
              description: el.CodigoAlterno,
              currencyFormat: el.monedaPrecio,
              data: el,
            });
          }
        });

        sessionStorage.setItem('arregloArticulos', JSON.stringify(programs));
      } else {
        this.selectedIndividual = this.numbers;
      }
    },
    comparacionValor(disponibilidad, idCantidad) {
      let valor = this.numbers[idCantidad];
      if (disponibilidad < valor) {
        this.numbers[idCantidad] = disponibilidad;
      }
      if (valor < 0) {
        this.numbers[idCantidad] = 0;
      }
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

/* Input number Amount*/
.inputAmount {
  display: flex;
  width: 60px;
  height: 30px;
  padding: 4px;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.number-input input[type='number'] {
  font-family: sans-serif;
  width: 60px;
  border: 2px solid #379b52;
  font-size: 1rem;
  height: 100%;
  font-weight: bold;
  text-align: center;
  color: #379b52;
}

.number-input input[type='number']:disabled {
  border: 2px solid #e5e5e5;
}
</style>
