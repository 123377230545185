<template>
  <div>
    <b-button class="btn btn-blue-dark-outline" @click="show = true">
      {{ $t('proposal.Button') }}
    </b-button>
    <b-modal
      v-model="show"
      :title="this.$i18n.t('proposal.noProposal.title')"
      centered
      header-bg-variant="light"
      header-text-variant="dark"
    >
      <b-container fluid>
        <b-form-radio-group
          v-model="noProposalSelected"
          v-slot="{ ariaDescribedby }"
          v-for="(item, index) in noProposal"
          :key="index"
        >
          <b-form-radio :aria-describedby="ariaDescribedby" :text="item.text" :value="item.value" @change="getReason">{{
            item.value
          }}</b-form-radio>
        </b-form-radio-group>
        <b-form-textarea
          id="textarea"
          v-model="textNoProposal"
          :placeholder="this.$i18n.t('proposal.noProposal.placeholder')"
          rows="3"
          max-rows="6"
          :state="noProposalSelected.includes(' no ') ? textNoProposal.length >= 5 : null"
          :disabled="!noProposalSelected.includes(' no ')"
        ></b-form-textarea>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button variant="secundary" size="sm" @click="show = false">
            {{ $t('proposal.noProposal.buttonCancel') }}
          </b-button>
          <b-button variant="success" size="sm" @click="modalInfo" :disabled="disableValidation">
            {{ $t('proposal.noProposal.buttonConfirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
|
<script>
import { API_ROOT } from '../../Constants/Api';
import { ApiRequest } from '../../helpers/ApiRequest';
import { AlertForm, ICON } from '../../helpers/Alert';

export default {
  name: 'NoProposalModal',
  data() {
    return {
      show: false,
      noProposal: [],
      noProposalSelected: '',
      textNoProposal: '',
    };
  },
  async mounted() {
    try {
      let response = await ApiRequest.get(
        `${API_ROOT}causales/pais/${sessionStorage.getItem('country') === 'US' ? 'usa' : 'mexico'}`
      );
      let causal = response.response_causal;
      let data = causal.lista.map((el) => ({
        isActive: true,
        text: el.Etiqueta,
        value: el.Valor,
        property: causal.causal_propertie,
      }));
      this.noProposal = [
        ...data,
        {
          isActive: true,
          text: '',
          value: causal.comentario_label,
          property: causal.comentario_propertie,
        },
      ];
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
    }
  },
  computed: {
    disableValidation() {
      if (this.noProposalSelected === '') return true;
      if (this.noProposalSelected.includes(' no ')) {
        return this.textNoProposal.length < 5 ? true : false;
      }
      return false;
    },
  },

  methods: {
    getReason(e) {
      if (!e.includes(' no ')) this.textNoProposal = '';
    },
    async modalInfo() {
      try {
        let [dataSelected] = this.noProposal.filter((el) => el.value === this.noProposalSelected);
        if (!dataSelected) return;
        if (dataSelected.value.includes('Reason')) dataSelected.text = this.textNoProposal;

        let data = await ApiRequest.post(`${API_ROOT}causales/causalContacto`, {
          correo: sessionStorage.getItem('email'),
          propiedad: dataSelected.property,
          valor_propiedad: dataSelected.text,
        });
        AlertForm('Success', data.msg, ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.show = false;
      }
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';

.custom-control-inline {
  margin-bottom: 2%;
}
</style>
