var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "btn btn-blue-dark-outline",
          on: {
            click: function($event) {
              _vm.show = true
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("proposal.Button")) + " ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: this.$i18n.t("proposal.noProposal.title"),
            centered: "",
            "header-bg-variant": "light",
            "header-text-variant": "dark"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "secundary", size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.noProposal.buttonCancel")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "success",
                            size: "sm",
                            disabled: _vm.disableValidation
                          },
                          on: { click: _vm.modalInfo }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("proposal.noProposal.buttonConfirm")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _vm._l(_vm.noProposal, function(item, index) {
                return _c("b-form-radio-group", {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var ariaDescribedby = ref.ariaDescribedby
                          return [
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  "aria-describedby": ariaDescribedby,
                                  text: item.text,
                                  value: item.value
                                },
                                on: { change: _vm.getReason }
                              },
                              [_vm._v(_vm._s(item.value))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.noProposalSelected,
                    callback: function($$v) {
                      _vm.noProposalSelected = $$v
                    },
                    expression: "noProposalSelected"
                  }
                })
              }),
              _c("b-form-textarea", {
                attrs: {
                  id: "textarea",
                  placeholder: this.$i18n.t("proposal.noProposal.placeholder"),
                  rows: "3",
                  "max-rows": "6",
                  state: _vm.noProposalSelected.includes(" no ")
                    ? _vm.textNoProposal.length >= 5
                    : null,
                  disabled: !_vm.noProposalSelected.includes(" no ")
                },
                model: {
                  value: _vm.textNoProposal,
                  callback: function($$v) {
                    _vm.textNoProposal = $$v
                  },
                  expression: "textNoProposal"
                }
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }