const programsStarAtHome = ['YSAH 3', 'YSAH 4'];

export const getRadioValuesFunction = (e, programasSelected, coverageSelected) => {
  sessionStorage.setItem('Article', programasSelected);
  let value = e.target.value,
    name = e.target.getAttribute('internal'),
    price = e.target.getAttribute('price'),
    description = e.target.getAttribute('description'),
    currencyFormat = e.target.getAttribute('currency'),
    data = JSON.parse(e.target.getAttribute('data')),
    arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
  if (arregloArticulos === null) {
    arregloArticulos = [{ name, value, amount: '1', price, description, currencyFormat, data }];
  } else {
    let filterArreglo = arregloArticulos.filter((el) => el.name === name);
    if (filterArreglo.length > 0) {
      // si existe ese valor
      arregloArticulos = arregloArticulos.filter((el) => el.name !== name);
      arregloArticulos.push({
        name,
        value,
        amount: '1',
        price,
        description,
        currencyFormat,
        data,
      });
      if (!programsStarAtHome.includes(programasSelected[0])) {
        arregloArticulos = arregloArticulos.filter((el) => el.name !== 'COVERAGE');
        coverageSelected = '';
      }
    } else {
      arregloArticulos.push({
        name,
        value,
        amount: '1',
        price,
        description,
        currencyFormat,
        data,
      });
    }
  }
  sessionStorage.setItem('arregloArticulos', JSON.stringify(arregloArticulos));
  console.log("Resultado ---> ", sessionStorage.getItem('arregloArticulos') )
  return { coverageSelected };
};
