// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/global.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../assets/css/proposal.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n/* Input number Amount*/\n.inputAmount[data-v-5a422450] {\n  display: flex;\n  width: 60px;\n  height: 30px;\n  padding: 4px;\n}\ninput[type='number'][data-v-5a422450] {\n  -webkit-appearance: textfield;\n  -moz-appearance: textfield;\n  appearance: textfield;\n}\n.number-input input[type='number'][data-v-5a422450] {\n  font-family: sans-serif;\n  width: 60px;\n  border: 2px solid #379b52;\n  font-size: 1rem;\n  height: 100%;\n  font-weight: bold;\n  text-align: center;\n  color: #379b52;\n}\n.number-input input[type='number'][data-v-5a422450]:disabled {\n  border: 2px solid #e5e5e5;\n}\n", ""]);
// Exports
module.exports = exports;
