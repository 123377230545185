var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "text-center",
      attrs: {
        header:
          (this.techniquesConfig[0] &&
            (_vm.country === "MX"
              ? this.techniquesConfig[0].label_category
              : this.$i18n.t("proposal.Techniques"))) ||
          this.$i18n.t("proposal.Techniques")
      },
      on: { change: _vm.getCheckboxValues }
    },
    [
      _vm.isLoading || _vm.isLoadingProducts
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-spinner", {
                attrs: { variant: "primary", label: "Text Centered" }
              })
            ],
            1
          )
        : _c(
            "b-card-text",
            [
              _c("div", { staticClass: "search-product" }, [
                _c(
                  "div",
                  { staticClass: "groupSearch mb-3" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "inputSearch", attrs: { size: "sm" } },
                      [
                        _c("b-form-input", {
                          staticClass: "custom-input",
                          attrs: {
                            id: "search-technics",
                            type: "search",
                            placeholder: this.$i18n.t("welcome.search")
                          },
                          model: {
                            value: _vm.filterOptions,
                            callback: function($$v) {
                              _vm.filterOptions = $$v
                            },
                            expression: "filterOptions"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("hr"),
                _c("div", { staticClass: "title-items" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("proposal.Article")))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("proposal.Price")) +
                        " (" +
                        _vm._s(_vm.country === "MX" ? "MXN " : "USD ") +
                        ")"
                    )
                  ])
                ])
              ]),
              _vm._l(_vm.techniques, function(item, index) {
                return _c("b-form-checkbox-group", {
                  key: index,
                  attrs: { stacked: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var ariaDescribedby = ref.ariaDescribedby
                          return [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  "aria-describedby": ariaDescribedby,
                                  name: item.category,
                                  value: item
                                }
                              },
                              [
                                item.top
                                  ? [
                                      _c("b", { staticClass: "b-ingen" }, [
                                        _vm.proposalingen.msg != null ||
                                        _vm.proposalingen.msg != undefined
                                          ? _c(
                                              "span",
                                              { staticClass: "no-ingen" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    class: {
                                                      "ingenes-class": _vm.proposalingen.msg.find(
                                                        function(x) {
                                                          return (
                                                            x.cfgd_articulo ==
                                                              item.value &&
                                                            x.cfgd_Categoria ==
                                                              item.data
                                                                .Categoria
                                                          )
                                                        }
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.description
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("b", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-secondary"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                item.price
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _vm.amountTop - 1 === index
                                                      ? _c("hr")
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "no-ingen" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.description) +
                                                    " "
                                                ),
                                                _c("b", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-secondary"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            item.price
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                      ])
                                    ]
                                  : [
                                      _vm.proposalingen.msg != null ||
                                      _vm.proposalingen.msg != undefined
                                        ? _c(
                                            "span",
                                            { staticClass: "no-ingen" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  class: {
                                                    "ingenes-class": _vm.proposalingen.msg.find(
                                                      function(x) {
                                                        return (
                                                          x.cfgd_articulo ==
                                                            item.value &&
                                                          x.cfgd_Categoria ==
                                                            item.data.Categoria
                                                        )
                                                      }
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.description) +
                                                      " "
                                                  ),
                                                  _c("b", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-secondary"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              item.price
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "no-ingen" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.description)
                                              ),
                                              _c("b", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-secondary"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          item.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          )
                                    ]
                              ],
                              2
                            ),
                            _vm.amountTop - 1 === index ? [_c("hr")] : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.techniquesSelected,
                    callback: function($$v) {
                      _vm.techniquesSelected = $$v
                    },
                    expression: "techniquesSelected"
                  }
                })
              }),
              !_vm.techniques.length
                ? _c("p", { staticClass: "my-2" }, [
                    _vm._v("No result for techniques")
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "outline-danger" },
                  on: { click: _vm.resetCheck }
                },
                [_vm._v("Reset")]
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }