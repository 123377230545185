var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    _vm._l(_vm.selectedBundle[0].phases, function(phase) {
      return _c(
        "b-card",
        {
          key: phase.id,
          staticClass: "mb-3",
          attrs: {
            "border-variant": "primary",
            "header-bg-variant": "primary"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "b-row",
                      {
                        staticClass: "d-flex justify-content-between px-3",
                        attrs: { "align-v": "center" }
                      },
                      [
                        _c("h6", { staticClass: "text-white mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                phase.phaseName +
                                  " " +
                                  (phase.optional ? "- Optional" : "")
                              ) +
                              " "
                          )
                        ]),
                        !_vm.creatingBundle
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "btn-phase",
                                attrs: {
                                  size: "sm",
                                  disabled: _vm.hasBranches(phase.id)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.createBundleBusinessPhase(
                                      phase.id
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Create Business Phase ")]
                            )
                          : _c(
                              "b-btn",
                              {
                                staticClass: "btn-phase",
                                attrs: { size: "sm", disabled: "" }
                              },
                              [
                                _c("b-spinner", {
                                  staticClass: "mr-2",
                                  attrs: { small: "" }
                                }),
                                _vm._v(" Creating Business Phase ")
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "b-card-text",
            [
              _c("b-table", {
                attrs: {
                  items: phase.products,
                  fields: _vm.fields,
                  responsive: "",
                  small: "",
                  bordered: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head()",
                      fn: function(ref) {
                        var label = ref.label
                        return [
                          _c("p", { staticClass: "text-center align-middle" }, [
                            _vm._v(_vm._s(label))
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(originBranches)",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "b-form-tag",
                            {
                              attrs: {
                                variant: _vm.getTagVariant(
                                  item.originBranches,
                                  "origin"
                                ),
                                "no-remove": ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getBranch(
                                      item.originBranches,
                                      "origin"
                                    ) || "Not Set"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(destinationBranches)",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "b-form-tag",
                            {
                              attrs: {
                                variant: _vm.getTagVariant(
                                  item.destinationBranches,
                                  "destination"
                                ),
                                "no-remove": ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getBranch(
                                      item.destinationBranches,
                                      "destination"
                                    ) || "Not Set"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }