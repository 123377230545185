var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-group",
    { attrs: { deck: "" } },
    [
      _c(
        "b-card",
        {
          staticClass: "text-center tableMedicine",
          attrs: { header: this.$i18n.t("proposal.Medicine.titleIndividual") }
        },
        [
          _c(
            "b-card-text",
            { staticClass: "mt-3 ml-4 tableMedicineItem" },
            [
              _c(
                "b-row",
                [
                  _c("b-col"),
                  _c(
                    "b-col",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "inputSearch", attrs: { size: "md" } },
                        [
                          _c("b-form-input", {
                            staticClass: "custom-input",
                            attrs: {
                              id: "filter-input-2",
                              type: "search",
                              placeholder: this.$i18n.t(
                                "proposal.Medicine.searchIndividual"
                              )
                            },
                            model: {
                              value: _vm.filterIndividual,
                              callback: function($$v) {
                                _vm.filterIndividual = $$v
                              },
                              expression: "filterIndividual"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-icon", {
                        staticClass: "input__search__icon",
                        attrs: {
                          icon: "search",
                          "aria-hidden": "true",
                          "flip-h": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "selectableTable",
                staticClass: "tableFirst",
                attrs: {
                  items: _vm.itemsIndividual,
                  fields: _vm.fieldsIndividual,
                  filter: _vm.filterIndividual,
                  "filter-included-fields": _vm.filterIndividualOn,
                  stacked: "md",
                  "show-empty": "",
                  small: "",
                  borderless: "",
                  hover: "",
                  "table-variant": "light",
                  "sort-icon-left": "",
                  "select-mode": "multi",
                  selectable: "",
                  "selected-variant": "",
                  busy: _vm.isLoading,
                  "empty-text": this.$i18n.t("proposal.noFound")
                },
                on: { "row-selected": _vm.onRowSelectedIndividual },
                scopedSlots: _vm._u([
                  {
                    key: "cell(CodigoAlterno)",
                    fn: function(row) {
                      return [_c("b", [_vm._v(_vm._s(row.value))])]
                    }
                  },
                  {
                    key: "cell(PrecioLista)",
                    fn: function(row) {
                      return [
                        _c("b", [_vm._v(_vm._s(_vm.formatNumber(row.value)))])
                      ]
                    }
                  },
                  {
                    key: "cell(Disponible)",
                    fn: function(row) {
                      return [
                        row.value
                          ? _c("b", [
                              _vm._v(
                                _vm._s(+(Math.round(row.value + "e+2") + "e-2"))
                              )
                            ])
                          : _c("b", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(row.value || 0))
                            ])
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(ref) {
                      var rowSelected = ref.rowSelected
                      var item = ref.item
                      return [
                        rowSelected
                          ? [
                              _vm.country === "MX"
                                ? _c("div", { staticClass: "inputAmount" }, [
                                    _c("div", { staticClass: "number-input" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.numbers[item.prod_id],
                                            expression: "numbers[item.prod_id]"
                                          }
                                        ],
                                        staticClass: "quantity mr-3",
                                        attrs: {
                                          size: "sm",
                                          min: "0",
                                          name: "quantity",
                                          type: "number",
                                          max: item.Disponible,
                                          disabled: !item.Disponible
                                        },
                                        domProps: {
                                          value: _vm.numbers[item.prod_id]
                                        },
                                        on: {
                                          keyup: function($event) {
                                            return _vm.comparacionValor(
                                              item.Disponible || 0,
                                              item.prod_id
                                            )
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.numbers,
                                              item.prod_id,
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]),
                                    item.Disponible
                                      ? _c(
                                          "span",
                                          { attrs: { "aria-hidden": "true" } },
                                          [_vm._v("✓")]
                                        )
                                      : _c(
                                          "small",
                                          {
                                            staticClass: "text-danger",
                                            attrs: { "aria-hidden": "true" }
                                          },
                                          [_vm._v("Sin Stock")]
                                        ),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Selected")
                                    ])
                                  ])
                                : _c("div", [
                                    _c(
                                      "span",
                                      { attrs: { "aria-hidden": "true" } },
                                      [_vm._v("✓")]
                                    ),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Selected")
                                    ])
                                  ])
                            ]
                          : [
                              _vm.country === "MX"
                                ? _c("div", { staticClass: "inputAmount" }, [
                                    _c("div", { staticClass: "number-input" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.numbers[item.prod_id],
                                            expression: "numbers[item.prod_id]"
                                          }
                                        ],
                                        staticClass: "quantity",
                                        attrs: {
                                          size: "sm",
                                          min: "0",
                                          name: "quantity",
                                          value: "1",
                                          type: "number",
                                          max: item.Disponibilidad,
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.numbers[item.prod_id]
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.numbers,
                                              item.prod_id,
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                : _vm._e()
                            ]
                      ]
                    }
                  },
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center text-primary my-2" },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v("Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }