<template>
  <!-- <b-card
    :header="
    (this.coverageConfig[0] && ( country === 'MX'? this.coverageConfig[0].label_category: this.$i18n.t('proposal.Coverage'))) || 
    this.$i18n.t('proposal.Coverage')
    "
    class="text-center"
    @change="getRadioValues"
  > -->
  <b-card
    :header="
    (this.coverageConfig[0] && ( country === 'MX'? this.coverageConfig[0].label_category: this.$i18n.t('proposal.Coverage'))) || 
    this.$i18n.t('proposal.Coverage')
    "
    class="text-center"
    @change="getRadioValues"
  >
    <b-card-text>
      <div class="text-center" v-if="!coverage.length">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <b-form-radio-group
        v-model="coverageSelected"
        v-slot="{ ariaDescribedby }"
        v-for="(item, index) in coverage"
        :key="index"
        v-else
      >
        <b-form-radio
          :internal="item.category"
          :aria-describedby="ariaDescribedby"
          :name="item.category"
          :price="item.price"
          :value="[item.articulo, item.name]"
          :description="item.name"
          :currency="item.currency"
          :data="JSON.stringify(item.data)"
          >
          <!-- {{ item.name }}  <p>xx</p> -->
          <!-- </b-form-radio> -->
          <br>

<!-- {{ item.articulo + '  ' + item.name }}<br> -->

          <template v-if="!coverage.length"> 
            <b class="b-ingen"
              >{{ item.name }}
            </b
            ></template
          >
          <template v-else>
            <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen">
              <!-- <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.alterName && x.cfgd_Categoria == item.category) }" > -->
              <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.alterName && x.cfgd_Categoria == item.category) }" >
                {{item.name}}
              </label>
            </span>
            <span v-else class="no-ingen">
              {{item.name}}
            </span>
            </template
          >
        </b-form-radio>
      </b-form-radio-group>
      <b-button size="sm" variant="outline-danger" @click="resetCheck()"
        >Reset</b-button
      >
    </b-card-text>
  </b-card>
</template>

<script>
import { API_PRODUCTO_CATEGORIA } from "../../Constants/Api";
import { getRadioValuesFunction } from "../../helpers/getRadioValue.js";
import { ApiRequest } from '../../helpers/ApiRequest';
import { AlertForm, ICON } from '../../helpers/Alert';

export default {
  name: "CoverageSection",
  props: ["programasSelected", "coverageConfig", "proposalingen"],
  data() {
    return {
      coverage: [],
      coverageSelected: "",
      listArticles: [],
      country: '',
    };
  },

  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.listArticles =
      JSON.parse(sessionStorage.getItem("arregloArticulos")) || [];

    await this.getCoverageList();
    await this.propuestaIngenesCoverage();
  },
  beforeRouteLeave(to, from, next) {
    // Reiniciar la selección de coverage al abandonar la ruta
    this.resetCheck();
    next();
  },
  watch: {
    coverageConfig() {
      setTimeout(() => {
        this.getCoverageList();
      }, 2000);
    },
  },
  methods: {
    isChecked(name, value) {
      let validacion = this.listArticles.filter(
        (item) => item.name === name && item.value.includes(value)
      );
      return validacion.length === 1;
    },
    async getCoverageList() {
      let dataCoverage = [];
      if (this.coverageConfig.length) {
        dataCoverage = this.coverageConfig;
      } else {
        try {
          let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}COVERAGE`);
          dataCoverage = data.productos;
        } catch (err) {
          return AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      }
      this.coverage = dataCoverage.map((item) => {
        if (this.isChecked(item.Categoria, item.Articulo)) {
          
          this.coverageSelected = [item.Articulo, item.CodigoAlterno];
          this.$emit('resultPrograms', this.coverageSelected);
        }
        return {
          isActive: true,
            name: item.CodigoAlterno,
            articulo: item.Articulo,
            price: item.PrecioLista,
            category: item.Categoria,
            alterName: item.Articulo,
            currency: item.monedaPrecio,
            data: item,
        };
      });
    },
    resetCheck() {
      this.coverageSelected = '';
      this.$emit('resultCoverage', '');

      const arregloArticulos = JSON.parse(
        sessionStorage.getItem("arregloArticulos")
      );
      sessionStorage.setItem(
        "arregloArticulos",
        JSON.stringify(arregloArticulos.filter((item) => item.name !== "COVERAGE"))
      );

    },
    getRadioValues(e) {
      let { coverageSelected } = getRadioValuesFunction(
        e,
        this.programasSelected,
        this.coverageSelected
      );
      this.$emit("resultCoverage", coverageSelected);
    },
    propuestaIngenesCoverage(){

if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
  if (this.proposalingen.msg.length) {
    this.coverage.forEach(item => {
      let laboratoryIngen = this.proposalingen.msg.find(x => x.cfgd_articulo == item.alterName && x.cfgd_Categoria == item.category)  
      if(laboratoryIngen != null){
        if(this.coverageSelected.length === 0){

          this.coverageSelected = [item.data.Articulo, item.data.CodigoAlterno];
          this.$emit('resultCoverage', this.coverageSelected);
          //this.$emit('resultPrograms', this.programasSelected);
          
          let value = item.name+","+item.name,
          name = item.category,
          price = item.price,
          description = item.name,
          currencyFormat = item.currency,
          data = item.data,
          arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
          
          if(arregloArticulos.find(x => x.description == item.name) == null ){
            arregloArticulos.push({
              name,
              value,
              amount: '1',
              price,
              description,
              currencyFormat,
              data,
            });

          }      
          sessionStorage.setItem('arregloArticulos', JSON.stringify(arregloArticulos)); 
        } 
      }
    }); 
  }
}
}
  },
  
};
</script>

<style scoped>
@import "../../assets/css/global.css";
@import "../../assets/css/proposal.css";
</style>
