<template>
  <b-card
    :header="(this.becConfig[0] && ( country === 'MX'? this.becConfig[0].label_category : this.$i18n.t('proposal.ProgramsUsa') ) ) "
    @change="getRadioValues"
  >
    <div class="text-center" v-if="isLoading || isLoadingProducts">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card-text v-else>
      <div class="search-programs">
        <div class="groupSearch mb-3">
          <b-input-group size="sm" class="inputSearch">
            <b-form-input
              id="search-programs"
              v-model="filterOptions"
              type="search"
              :placeholder="this.$i18n.t('welcome.search')"
              class="custom-input"
            >
            </b-form-input>
          </b-input-group>
        </div>
        <hr />
        <div class="title-items">
          <span>{{ $t('proposal.Article') }}</span>
          <span>{{ $t('proposal.Price') }} ({{ country === 'MX' ? 'MXN ' : 'USD ' }})</span>
        </div>
      </div>
      <!-- <div v-if="isYsah"><h5>{{ $t('admin.bussiness.notBecYsah') }}</h5></div> -->
      <div >
        <b-form-radio-group
        v-model="programasSelected"
        v-slot="{ ariaDescribedby }"
        v-for="(item, index) in programas"
        :key="index"
      >
        <b-form-radio
          :internal="item.category"
          :aria-describedby="ariaDescribedby"
          :name="item.category"
          :price="item.price"
          :value="[item.articulo, item.name]"
          :description="item.name"
          :currency="item.currency"
          :data="JSON.stringify(item.data)"
        >
        <template v-if="item.top">
            <b class="b-ingen"
              >{{ item.name }}
              <hr v-if="amountTop - 1 === index" /> </b
            ><b
              ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
            </b></template
          >
          <template v-else>
            <span v-if="proposalingen.msg != null || proposalingen.msg != undefined" class="no-ingen" >
              <label :class="{ 'ingenes-class': proposalingen.msg.find(x => x.cfgd_articulo == item.alterName && x.cfgd_Categoria == item.category) }">
                {{item.name}}<b
                  ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
                </b>
              </label>
            </span>
            <span v-else class="no-ingen">
              {{item.name}}<b
                ><span class="text-secondary">{{ formatPrice(item.price) }}</span>
              </b>
            </span>
            </template
          >
        </b-form-radio>
      </b-form-radio-group>
      </div>
      
      <p v-if="!programas.length" class="my-2">No result for Programs</p>
      <b-button size="sm" variant="outline-danger"  @click="resetCheck">Reset</b-button>
      <b-card-text class="text-right">
        <b-button
          variant="outline-primary"
          class="btn-blue-dark-outline"
          v-b-modal.modal-all-programs
          @click="handleModal"
          v-if="createNewRequest"
        >
          {{ $t('proposal.AdminPermisions.buttonShow') }} +
        </b-button>
        <b-button variant="outline-primary" class="btn-blue-dark-outline" @click="detailsRequest" v-else>
          {{ $t('proposal.AdminPermisions.statusRequest') }} +
        </b-button>
      </b-card-text>
    </b-card-text>

    <b-modal id="modal-all-programs" :title="this.$i18n.t('proposal.AdminPermisions.title')" no-close-on-backdrop>
      <p class="my-4">
        {{ $t('proposal.AdminPermisions.text') }}
      </p>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-programs"
          v-model="allProgramsSelected"
          :options="optionsAllPrograms"
          :aria-describedby="ariaDescribedby"
          name="allProgramas"
          class="custom-check-input"
        ></b-form-checkbox-group>
      </b-form-group>
      <p class="my-4">
        {{ $t('proposal.AdminPermisions.justify') }}
      </p>
      <b-form-textarea
        v-model="textJustify"
        :placeholder="this.$i18n.t('proposal.AdminPermisions.justifyPlaceholder')"
        rows="3"
        max-rows="6"
        :state="textJustify.length >= 10"
      ></b-form-textarea>
      <template #modal-footer="{ cancel }">
        <div class="w-100 text-right">
          <b-button size="sm" class="blue-dark" v-b-modal.modal-request @click="sendRequest" :disabled="!isValid">
            {{ $t('proposal.AdminPermisions.buttonSend') }}
          </b-button>
          <b-button variant="secundary" size="sm" @click="cancel()">
            {{ $t('proposal.AdminPermisions.buttonCancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-request" ref="modal-request" class="text-center" no-close-on-backdrop>
      <template #modal-header="">
        <!-- Emulate built in modal header close button action -->
        <h5></h5>
      </template>
      <b-row class="custom-row px-4">
        <h4 class="my-2">{{ $t('proposal.AdminPermisions.wait') }}</h4>
        <p class="mb-5">{{ $t('proposal.AdminPermisions.process') }}</p>
        <b-iconstack font-scale="5" animation="spin">
          <b-icon stacked icon="arrow-clockwise" animation="throb" variant="secondary" scale="1"></b-icon>
        </b-iconstack>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="secundary" size="sm" class="float-right" @click="cancelRequest">
            {{ $t('proposal.AdminPermisions.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { API_ROOT, API_PRODUCTO_CATEGORIA } from '../../Constants/Api';
import { getRadioValuesFunction } from '../../helpers/getRadioValue.js';
import Swal from 'sweetalert2';
import { AlertForm, AlertHTML, ICON } from '../../helpers/Alert';
import { DateFullDay } from '../../helpers/Date';
import { ApiRequest } from '../../helpers/ApiRequest';
import { NumberFormat } from '../../helpers/NumberFormat';

export default {
  name: 'ProgramSection',
  props: ['coverageSelected', 'dataProducts', 'dataAllProducts', 'isLoadingProducts', 'becConfig','proposalingen'],
  data() {
    return {
      programas: [],
      allPrograms: [],
      conocimiento: {},
      optionsAllPrograms: [],
      allProgramsSelected: [],
      textJustify: '',
      programasSelected: [],
      timer: null,
      count: 0,
      email: sessionStorage.getItem('email'),
      createNewRequest: false,
      detailsInfo: {},
      country: '',
      listArticles: [],
      filterOptions: '',
      isLoading: false,
      amountTop: null,
      isYsah: null
    };
  },
  async mounted() {
    this.country = sessionStorage.getItem('country');
    this.configBussiness = this.$store.state.allConfigBussiness;
    this.conocimiento = JSON.parse(sessionStorage.getItem('conocimiento'));
    this.listArticles = JSON.parse(sessionStorage.getItem('arregloArticulos')) || [];
    this.updateBec();
    try {
      await this.updateTableAuthPrograms();
      const data = await ApiRequest.post(`${API_ROOT}cotizacion/control-negocio`, {
          vid: sessionStorage.getItem('vid'),
          sede_origen: JSON.parse(sessionStorage.getItem('sedeOrigen')).name,
        });
        this.isYsah = data.detalle_control.Negocios.length ? true : false
    } catch (error) {
      console.log(error);
    }
    await this.propuestaIngenesPrograms();
  },

  computed: {
    isValid() {
      return this.textJustify.length >= 10 && this.allProgramsSelected.length > 0;
    },
  },
  watch: {
    async dataProducts() {
      await this.updateBec();
    },
    filterOptions() {
      this.programas = this.allPrograms.filter((el) =>
        el.name.toLowerCase().includes(this.filterOptions.toLowerCase())
      );
      this.amountTop = this.programas.filter((el) => el.top === true).length;
    },
    async becConfig() {
      setTimeout(() => {
        this.updateBec();
      }, 2000);
    },
    async pendings_auth() {
      try {
        await this.updateTableAuthPrograms();
      } catch (error) {
        console.log(error);
      }
    },
  },
  methods: {
    async updateTableAuthPrograms() {
      try {
        const pending = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/estado/Pendiente`);
        if(pending.datos.length > 0){
          this.apiRequestProgram(1, pending.datos[0])
        }
      } catch (err) {
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } 
      }
    },
    async apiRequestProgram(status, item, text = "") {
      try {
        /*await ApiRequest.put(`${API_ROOT}solicitudesProgramas`, {
          id: item._id,
          correo_autoriza: "soporte@ingenes.com",
          estado: status,
          justificacion_admin: text,
          programas_autorizados: item.programas_solicitados,
        });*/
        console.log(text);
        AlertForm(this.$i18n.t("admin.auth.submit"), "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorUpdateRequestPrograms"), ICON.ERROR);
      }
    },
    async updateBec() {
      try {
        let dataBec = [];
        this.isLoading = true;
        if (this.becConfig.length > 0) {
          dataBec = this.becConfig;
        } else {
          if (this.country === 'US') {
            let validateScore = this.conocimiento.new_score ? 'newScore' : 'score';
            let age = sessionStorage.getItem('edad');
            let knowledge = this.conocimiento.conocimiento_fertilidad;
            let data = await ApiRequest.get(
              `${API_ROOT}productos/${validateScore}/edad/${age}/conocimiento/${knowledge}`
            );
            let configPrograms = [];
            for (const el of data.productos) {
              configPrograms.push(this.configBussiness.find((config) => el.Articulo === config.Articulo));
            }
            configPrograms = configPrograms.filter((el) => el !== undefined);
            dataBec = configPrograms;
          } else {
            dataBec = this.dataProducts;
          }
        }
        let dataArray = [];
        let cantidad = 0;
        dataBec.forEach((item) => {
          if (item.isTop) cantidad++;
          dataArray.push({
            isActive: true,
            name: item.CodigoAlterno,
            articulo: item.Articulo,
            price: item.PrecioLista,
            category: item.Categoria,
            alterName: item.Articulo,
            currency: item.monedaPrecio,
            data: item,
            top: item.isTop ? true : false,
          });
          if (this.isChecked(item.Categoria, item.Articulo)) {
            this.programasSelected = [item.Articulo, item.CodigoAlterno];
            this.$emit('resultPrograms', this.programasSelected);
          }
        });
        this.amountTop = cantidad;
        this.programas = dataArray;
        this.allPrograms = dataArray;
        await this.requestValidation();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoading = false;
      }
    },
    isChecked(name, value) {
      let validacion = this.listArticles.filter((el) => el.name === name && el.value.split(',')[0] === value);
      return validacion.length === 1;
    },
    async requestValidation() {
      try {
        let result = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/correo/${this.email}`);
        if (!result.resultado.length) return (this.createNewRequest = 'new');
        this.createNewRequest = result.resultado[1].crearSolicitud;
        if (result.resultado[0].estado_autorización === 'Aprobada') {
          sessionStorage.setItem('approvedPrograms', JSON.stringify(result.resultado[0].programas_solicitados));
          sessionStorage.setItem('newPrograms', true);
          this.approvedPrograms();
        }
        this.detailsInfo = result.resultado[0];
      } catch (err) {
        throw { status: err.status, statusText: err.statusText };
      }
    },
    detailsRequest() {
      let html = `
        <b> ${this.$i18n.t(
          'proposal.AdminPermisions.ProgramsRequest'
        )}: </b> ${this.detailsInfo.programas_solicitados.join(', ')}<br>
        <b> ${this.$i18n.t('proposal.AdminPermisions.AuthorizationStatus')}: </b> ${
        this.detailsInfo.estado_autorización
      }<br>
        <b> ${this.$i18n.t('proposal.AdminPermisions.DateRequest')}: </b> ${DateFullDay(
        this.detailsInfo.fecha_solicitud
      )}<br>
        <b> ${this.$i18n.t('proposal.AdminPermisions.Applicant')}: </b> ${this.detailsInfo.solicitante} 
      `;
      if (this.detailsInfo.estado_autorización === 'Rechazado') {
        html += `<br><b> ${this.$i18n.t('proposal.AdminPermisions.reason')}: </b> ${
          this.detailsInfo.motivo_autoriza_no_autoriza
        }
        `;
      }
      if (['Rechazado', 'Aprobada'].includes(this.detailsInfo.estado_autorización)) {
        html += `<br><b> ${this.$i18n.t('proposal.AdminPermisions.authorizer')}: </b> ${this.detailsInfo.autorizador}
        `;
      }

      AlertHTML(this.$i18n.t('proposal.AdminPermisions.detailsRequest'), html, ICON.INFO);
    },
    async getRadioValues(e) {        
         
          let { coverageSelected } = getRadioValuesFunction(e, this.programasSelected, this.coverageSelected);
        
          this.$emit('resultCoverage', coverageSelected);
          this.$emit('resultPrograms', this.programasSelected);
  

    },
    async approvedPrograms() {
      const programsArray = JSON.parse(sessionStorage.getItem('approvedPrograms'));
      if (programsArray && programsArray.length) {
        try {
          let dataProgramsList = [];
          if (this.country === 'US') {
            /* let data = await ApiRequest.get(
              `${API_PRODUCTO_CATEGORIA}BEC INTERNACIONAL`
            ); */
            dataProgramsList = this.configBussiness;
          } else {
            dataProgramsList = this.dataAllProducts;
          }
          const approvedPrograms = dataProgramsList.filter((el) => programsArray.includes(el.Articulo));
          // validacion que no se repitan los programas
          const programsPatient = this.programas.map((el) => el.alterName);
          const filterPrograms = approvedPrograms.filter((el) => !programsPatient.includes(el.Articulo));
          const dataPrograms = filterPrograms.map((item) => {
            if (this.isChecked(item.Categoria, item.Articulo)) {
              this.programasSelected = [item.Articulo, item.CodigoAlterno];
              this.$emit('resultPrograms', this.programasSelected);
            }
            return {
              isActive: true,
              name: item.CodigoAlterno,
              articulo: item.Articulo,
              price: item.PrecioLista,
              category: item.Categoria,
              alterName: item.Articulo,
              currency: item.monedaPrecio,
              data: item,
            };
          });
          dataPrograms.forEach((el) => {
            this.programas.push(el);
          });
        } catch (err) {
          throw { status: err.status, statusText: err.statusText };
        }
      }
    },
    async handleModal() {
      if (this.createNewRequest !== 'new') this.detailsRequest();
      try {
        let dataPrograms = [];
        if (this.country === 'US') {
          let data = await ApiRequest.get(`${API_PRODUCTO_CATEGORIA}BEC INTERNACIONAL`);
          dataPrograms = data.productos;
        } else {
          dataPrograms = this.dataAllProducts;
        }

        let currentPrograms = this.programas.map((el) => el.articulo);
        const mapPrograms = dataPrograms.map((el) => {
          return {
            text: el.CodigoAlterno,
            value: el.Articulo,
          };
        });
        this.optionsAllPrograms = mapPrograms.filter((el) => !currentPrograms.includes(el.value));
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },

    async sendRequest() {
      try {
        const data = await ApiRequest.post(`${API_ROOT}solicitudesProgramas`, {
          correo_doc: this.$store.state.userResponse.email,
          correo_pac: sessionStorage.getItem('email'),
          justificacion_doc: this.textJustify,
          programas_solicitados: this.allProgramsSelected,
        });
        if(data.ok == true){
          await this.updateTableAuthPrograms();
        }
        await this.requestValidation();
        this.timer = setInterval(() => {
          this.timerPetition(data.registro);
          this.count++;
        }, 10000);
      } catch (err) {
        this.$refs['modal-request'].hide();
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.$bvModal.hide('modal-all-programs');
      }
    },

    async timerPetition(id) {
      try {
        const data = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/id/${id}`);
        if (data.resultado[0].estado_autorización === 'Aprobada') {
          sessionStorage.setItem('approvedPrograms', JSON.stringify(data.resultado[0].programas_solicitados));
          this.approvedPrograms();
          Swal.fire({
            icon: 'success',
            title: this.$i18n.t('proposal.AdminPermisions.messageSucess'),
            html: `<b>${this.$i18n.t(
              'proposal.AdminPermisions.approvedPrograms'
            )}: </b> ${data.resultado[0].programas_solicitados.join(', ')}`,
            footer: `<b>${this.$i18n.t('proposal.AdminPermisions.authorized')}: </b> ${data.resultado[0].autorizador}`,
          });

          this.cancelProcess();
        } else if (data.resultado[0].estado_autorización === 'Rechazado') {
          Swal.fire({
            icon: 'error',
            title: this.$i18n.t('proposal.AdminPermisions.messageError'),
            html: `<b>${this.$i18n.t('proposal.AdminPermisions.justification')} </b> ${
              data.resultado[0].motivo_autoriza_no_autoriza
            }`,
            footer: `<b>${this.$i18n.t('proposal.AdminPermisions.unauthorized')}: </b> ${
              data.resultado[0].autorizador
            }`,
          });
          this.cancelProcess();
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    cancelRequest() {
      this.$refs['modal-request'].hide();
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'modal-request') {
          return this.cancelProcess();
        }
      });
      return this.cancelProcess();
    },
    cancelProcess() {
      this.count = 0;
      this.$bvModal.hide('modal-request');
      clearInterval(this.timer);
    },
    resetCheck() {
      this.programasSelected = '';
      this.$emit('resultCoverage', '');
      this.$emit('resultPrograms', '');
      sessionStorage.removeItem('Article');
      const arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
      sessionStorage.setItem(
        'arregloArticulos',
        JSON.stringify(arregloArticulos.filter((el) => !['BEC INTERNACIONAL', 'BEC', 'COVERAGE'].includes(el.name)))
      );
    },
    formatPrice(price) {
      return NumberFormat(price);
    },
    propuestaIngenesPrograms(){
      if(this.proposalingen.msg != null || this.proposalingen.msg != undefined){
        if (this.proposalingen.msg.length) {
          this.allPrograms.forEach(item => {
            let laboratoryIngen = this.proposalingen.msg.find(x => x.cfgd_articulo == item.alterName && x.cfgd_Categoria == item.category)
            if(laboratoryIngen != null){
              //sessionStorage.setItem('arregloArticulos', JSON.stringify([]));
                //si programselected.lenght es 0 o null inserte la propuesta ingenes si no lo omitirá
                if(this.programasSelected.length === 0 ){

                  this.programasSelected = [item.data.Articulo, item.data.CodigoAlterno];
                  //this.$emit('resultCoverage', this.coverageSelected);
                  this.$emit('resultPrograms', this.programasSelected);
                  
                  let value = item.name+","+item.name,
                  name = item.category,
                  price = item.price,
                  description = item.name,
                  currencyFormat = item.currency,
                  data = item.data,
                  arregloArticulos = JSON.parse(sessionStorage.getItem('arregloArticulos'));
                  
                  if(arregloArticulos.find(x => x.description == item.name) == null ){
                    arregloArticulos.push({
                      name,
                      value,
                      amount: '1',
                      price,
                      description,
                      currencyFormat,
                      data,
                    });

                  }
                  sessionStorage.setItem('arregloArticulos', JSON.stringify(arregloArticulos)); 
                }        
            }
          }); 
        }
      }
    },
    async getConjuntoMedicamentos() {
      try {
        const data = await ApiRequest.get(`${API_ROOT}preconfiguracion/getAllConjuntoMedicamentos`);
        if(data.ok == true){
          return data;
        }      
      } catch (err) {
        //this.$refs['modal-request'].hide();
        //AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } 
    },

  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';
@import '../../assets/css/proposal.css';

/** modal permissions */
.custom-row {
  padding: 20px 0px;
  font-size: 14px;
  margin-top: 3%;
  flex-direction: column;
  align-items: center;
}

.custom-check-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
}

.groupSearch {
  width: 50%;
}
</style>
